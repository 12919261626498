import axios from 'axios';
import Constantes from "../constantes";

//constantes
const rutaApi = Constantes.RUTA_API;

const dataInicial = {
    loadingAppRegala: false,
    historicRegala: null,
    errorDeCargaRegala: false,
    exitoActualizacionRegala: false,
    exitoEliminarRegala: false,
    dataCarregadaRegala: { estado: false },
}

//types
const LOADING_APP_REGALA = 'LOADING_APP_REGALA';
const OBTENER_HISTORICREGALAEXITO = 'OBTENER_HISTORICREGALAEXITO';
const ERROR_DE_CARGA_REGALA = 'ERROR_DE_CARGA_REGALA';
const ACTUALIZAR_REGALA_EXITO = 'ACTUALIZAR_REGALA_EXITO';
const RESETEA_EXITO_REGALA = 'RESETEA_EXITO_REGALA';
const SET_REGALA = 'SET_REGALA';
const SET_DATA_CARREGAT_REGALA = 'SET_DATA_CARREGAT_REGALA';
const ELIMINAR_REGALA_EXITO = 'ELIMINAR_REGALA_EXITO';
const RESETEA_DATACARREGADAREGALA = 'RESETEA_DATACARREGADAREGALA';

//reducer
export default function regalaReducer(state = dataInicial, action) {
    switch (action.type) {
        case LOADING_APP_REGALA:
            return { ...state, loadingAppRegala: true }
        case OBTENER_HISTORICREGALAEXITO:
            return { ...state, historicRegala: action.payload.array, errorDeCargaRegala: action.payload.errorDeCargaRegala, loadingAppRegala: false }
        case ERROR_DE_CARGA_REGALA:
            return { ...state, errorDeCargaRegala: true, loadingAppRegala: false }
        case ACTUALIZAR_REGALA_EXITO:
            return { ...state, errorDeCargaRegala: false, exitoActualizacionRegala: true }
        case RESETEA_EXITO_REGALA:
            return { ...state, exitoActualizacionRegala: false, loadingAppRegala: false, historicRegala: null, exitoEliminarRegala: false }
        case SET_REGALA:
            return { ...state, historicRegala: action.payload.array }
        case ELIMINAR_REGALA_EXITO:
            return { ...state, exitoEliminarRegala: true }
        case SET_DATA_CARREGAT_REGALA:
            return { ...state, dataCarregadaRegala: { estado: true, ...action.payload } }
        case RESETEA_DATACARREGADAREGALA:
            return { ...state, dataCarregadaRegala: { estado: false } }
        default:
            return { ...state }
    }
}

//acciones

export const setDataCarregadaRegalaAccion = (objeto) => (dispatch, getState) => {
    dispatch({
        type: SET_DATA_CARREGAT_REGALA,
        payload: objeto
    });
};

export const resetDataCarregadaRegalaAccion = () => (dispatch, getState) => {
    dispatch({
        type: RESETEA_DATACARREGADAREGALA
    });
};

export const resetRegalaGeneralMenu = () => (dispatch, getState) => {
    dispatch({
        type: RESETEA_EXITO_REGALA
    });
    dispatch({
        type: SET_REGALA,
        payload: {
            array: null
        }
    });
};

export const reseteaExitoRegalaAccion = () => (dispatch, getState) => {
    dispatch({
        type: RESETEA_EXITO_REGALA
    });
};

const convertirStringAFecha = (fechaString) => {
    const partes = fechaString.split('/');
    return new Date(partes[2], partes[1] - 1, partes[0]);
};

export const obtenerRegalaAccion = (objeto) => async (dispatch, getState) => {
    dispatch({
        type: LOADING_APP_REGALA
    });
    try {
        const formData = new FormData();
        formData.append("objeto", objeto);
        let apiUrl = rutaApi + "listar.php";
        const res = await axios.post(apiUrl, formData, {
            headers: {
                "Content-Type": "multipart/form-data",
            }
        });
        const registrosOrdenados = res.data.map(registro => ({
            ...registro,
            fecha: convertirStringAFecha(registro.fecha)
        })).sort((a, b) => b.fecha - a.fecha);
        dispatch({
            type: OBTENER_HISTORICREGALAEXITO,
            payload: {
                array: registrosOrdenados
            }
        })
    } catch (error) {
        dispatch({
            type: ERROR_DE_CARGA_REGALA
        })
    }
};

export const eliminarRegalaAccion = (objeto, id) => async (dispatch, getState) => {
    dispatch({
        type: LOADING_APP_REGALA
    });
    try {
        const formData = new FormData();
        formData.append("objeto", objeto);
        formData.append("id", id);
        let apiUrl = rutaApi + "eliminar.php";
        await axios.post(apiUrl, formData, {
            headers: {
                "Content-Type": "multipart/form-data",
            }
        });
        dispatch({
            type: ELIMINAR_REGALA_EXITO
        });
    } catch (error) {
        dispatch({
            type: ERROR_DE_CARGA_REGALA
        })
    }
};

export const actualitzarRegalaAccion = (objeto, datos, id, redimir, mail) => async (dispatch) => {
    dispatch({
        type: LOADING_APP_REGALA
    });
    try {
        const formData = new FormData();
        formData.append("objeto", objeto);
        formData.append("datos", JSON.stringify(datos));
        formData.append("id", id);
        const apiUrl = `${rutaApi}actualizar.php`;
        await axios.post(apiUrl, formData, {
            headers: { "Content-Type": "multipart/form-data" },
        });
        if (redimir) {
            await enviarMail(dispatch, mail);
        } else {
            dispatch({
                type: ACTUALIZAR_REGALA_EXITO
            });
        }
    } catch (error) {
        dispatch({
            type: ERROR_DE_CARGA_REGALA
        });
    };
};

const enviarMail = async (dispatch, mail) => {
    try {
        const formData = new FormData();
        formData.append('email', mail);
        formData.append('asunto', 'Tarjeta Regala Casa Amàlia canjeada');
        const apiUrl = `${rutaApi}enviar_mail.php`;
        const res = await axios.post(apiUrl, formData, {
            headers: { "Content-Type": "multipart/form-data" },
        });
        if (res.data.status !== "success") {
            dispatch({
                type: ERROR_DE_CARGA_REGALA
            });
        } else {
            dispatch({
                type: ACTUALIZAR_REGALA_EXITO
            });
        }
    } catch (error) {
        dispatch({
            type: ERROR_DE_CARGA_REGALA
        });
    };
};

export const calculCaducitat = (fecha) => (dispatch, getState) => {
    let fechaFutura = new Date(fecha);
    fechaFutura.setMonth(fechaFutura.getMonth() + 6);
    let fechaActual = new Date();
    return fechaFutura < fechaActual;
};

