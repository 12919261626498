import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useIntersection from "../useIntersection";
import { withRouter, useHistory } from "react-router-dom";
import {
    Box,
    Grid,
    Stack,
    useMediaQuery,
    Button,
    Paper,
    Divider
} from '@mui/material';
import ContentPasteSearchIcon from '@mui/icons-material/ContentPasteSearch';
import RateReviewIcon from '@mui/icons-material/RateReview';
import RedeemIcon from '@mui/icons-material/Redeem';
import logoCA from '../images/logo-casa-amalia-color-RGB.jpg';

//carga componentes

//estilos
import Clases from "../clases";

//importaciones acciones
import {
    setOnEstemAccion,
    setOpenColapse
} from '../redux/appDucks';

const Inicio = (props) => {
    const classes = Clases();
    const dispatch = useDispatch();
    const history = useHistory();
    const view = useRef();
    const inViewport = useIntersection(view, "0px");
    // if (inViewport.estado && !inViewport.completado) {
    //     window.location.reload(false);
    // };
    const { activo: logged, usuarioActivo: { nombre: usuari, rol } } = useSelector(store => store.variablesUsuario);
    const { openColapse } = useSelector(store => store.variablesApp);

    //states
    const breakpoints = {
        xs: useMediaQuery(theme => theme.breakpoints.only('xs')),
        sm: useMediaQuery(theme => theme.breakpoints.only('sm')),
        md: useMediaQuery(theme => theme.breakpoints.only('md')),
        lg: useMediaQuery(theme => theme.breakpoints.only('lg')),
        xl: useMediaQuery(theme => theme.breakpoints.only('xl'))
    };

    //useEffect

    useEffect(() => {
        if (!logged) {
            props.history.push('/login')
        }
    }, [logged, props.history]);

    useEffect(() => {
        if (inViewport.estado && !inViewport.completado) {
            window.location.reload(false);
        };
    }, [inViewport]);

    useEffect(() => {
        if (breakpoints.xs || breakpoints.sm || breakpoints.md || breakpoints.lg) {
            document.body.classList.add(classes.conScroll);
        } else {
            document.body.classList.add(classes.sinScroll);
        };
    }, []);

    useEffect(() => {
        dispatch(setOnEstemAccion('inici'));
    }, []);

    //funciones   

    const handleClick = (desti) => {
        const routes = {
            registres: '/registres',
            regala: '/regala',
            ressenyes: '/ressenyes',
            horesExtra: '/horesExtra'
        };
        if (routes[desti]) {
            history.push(routes[desti]);
            if (desti === "ressenyes") {
                if (!openColapse) {
                    dispatch(setOpenColapse(true));
                };
            };
        };
    };

    return (
        <div>
            {/* {console.log(breakpoints)} */}
            <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
                spacing={4}
                style={{ minHeight: '60vh' }}
            >
                <Grid item xs={12} md={6} lg={4}>
                    <Paper elevation={3}>
                        <Box
                            p={2}
                            mt={2}
                            textAlign="center"
                        >
                            <img src={logoCA} alt="logoCA" style={{ width: '100%', height: 'auto' }} />
                            <Divider variant="middle" flexItem />
                            <Stack p={2.5} my={3} spacing={1.5}>
                                {rol === "admin" && (
                                    <Button
                                        variant="contained"
                                        color='inherit'
                                        startIcon={<ContentPasteSearchIcon />}
                                        fullWidth
                                        size='large'
                                        sx={{ justifyContent: "flex-start" }}
                                        disableElevation
                                        onClick={() => handleClick("registres")}
                                        disabled={rol !== "admin"}
                                    >
                                        Contador propines Excel
                                    </Button>
                                )}
                                {rol === "admin" && (
                                    <Button
                                        variant="contained"
                                        color='inherit'
                                        startIcon={<ContentPasteSearchIcon />}
                                        fullWidth
                                        size='large'
                                        sx={{ justifyContent: "flex-start" }}
                                        disableElevation
                                        onClick={() => handleClick("horesExtra")}
                                        disabled={rol !== "admin"}
                                    >
                                        Contador hores extra Excel
                                    </Button>
                                )}
                                <Button
                                    variant="contained"
                                    color='inherit'
                                    startIcon={<RedeemIcon />}
                                    fullWidth
                                    size='large'
                                    sx={{ justifyContent: "flex-start" }}
                                    disableElevation
                                    //disabled={true}
                                    onClick={() => handleClick("regala")}
                                >
                                    Gestió Targetes Regala
                                </Button>
                                {rol === "admin" && (
                                    <Button
                                        variant="contained"
                                        color='inherit'
                                        startIcon={<RateReviewIcon />}
                                        fullWidth
                                        size='large'
                                        sx={{ justifyContent: "flex-start" }}
                                        disableElevation
                                        disabled={rol !== "admin"}
                                        onClick={() => handleClick("ressenyes")}
                                    >
                                        Gestió Ressenyes
                                    </Button>
                                )}
                            </Stack>
                        </Box>
                    </Paper>
                </Grid>
            </Grid>
        </div>
    )
}

export default withRouter(Inicio)