import { createStore, combineReducers, compose, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';

//import {composeWithDevTools} from 'redux-devtools-extension';

import appReducer from './appDucks';
import usuarioReducer from './usuarioDucks';
import registresReducer from './registresDucks';
import ressenyesReducer from './ressenyesDucks';
import regalaReducer from './regalaDucks';
import horesExtraReducer from './horesExtraDucks';

const rootReducer = combineReducers({   
    variablesApp: appReducer, 
    variablesUsuario: usuarioReducer,  
    variablesRegistres: registresReducer,
    variablesRessenyes: ressenyesReducer,
    variablesRegala: regalaReducer,
    variablesHoresExtra: horesExtraReducer,
});

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export default function generateStore() {
    const store = createStore(rootReducer, composeEnhancers(applyMiddleware(thunk)));
    return store;
}