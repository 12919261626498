import React, { forwardRef } from 'react';
import axios from 'axios';
import Constantes from "../constantes";
import MuiAlert from '@mui/material/Alert';
import { styled, alpha } from '@mui/material/styles';
import {
    Menu,
    Slide
} from '@mui/material';
import * as XLSX from 'xlsx-js-style';

//constantes
const { RUTA_API: rutaApi, PREU_HORA_EXTRA: preuHoraExtra } = Constantes;

const dataInicial = {
    onEstem: null,
    alerta: {
        abierto: false,
        mensaje: '',
        tipo: ''
    },
    dialog: { state: false, options: { children: "Hi" } },
    openColapse: false
}

//types
const SET_ONESTEM = 'SET_ONESTEM';
const SET_ALERTA = 'SET_ALERTA';
const SET_DIALOG = 'SET_DIALOG';
const SET_OPENCOLAPSE = 'SET_OPENCOLAPSE';

//reducer
export default function appReducer(state = dataInicial, action) {
    switch (action.type) {
        case SET_ONESTEM:
            return { ...state, onEstem: action.payload.valor }
        case SET_ALERTA:
            return { ...state, alerta: action.payload.objeto }
        case SET_DIALOG:
            return { ...state, dialog: action.payload.objeto }
        case SET_OPENCOLAPSE:
            return { ...state, openColapse: action.payload.valor }
        default:
            return { ...state }
    }
}

//acciones

export const Alert = forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export const setCurrentDateAccion = () => (dispatch, getState) => {
    const date = new Date();
    const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
    return (date.toLocaleDateString('ca-ES', options));
};

export const convertirFechaAFormatoDDMMAAAA = (fecha) => (dispatch, getState) => {
    const dia = fecha.getDate().toString().padStart(2, '0');
    const mes = (fecha.getMonth() + 1).toString().padStart(2, '0');
    const anio = fecha.getFullYear().toString();
    return `${dia}/${mes}/${anio}`;
};

export const setOpenDialog = (objeto) => (dispatch, getState) => {
    dispatch({
        type: SET_DIALOG,
        payload: {
            objeto: { state: true, options: objeto }
        }
    });
};

export const setCloseDialog = () => (dispatch, getState) => {
    dispatch({
        type: SET_DIALOG,
        payload: {
            objeto: { state: false, options: { children: "Hi" } }
        }
    });
};

export const setAlertaAccion = (objeto) => (dispatch, getState) => {
    dispatch({
        type: SET_ALERTA,
        payload: {
            objeto: objeto
        }
    });
};

export const setOnEstemAccion = (valor) => (dispatch, getState) => {
    dispatch({
        type: SET_ONESTEM,
        payload: {
            valor: valor
        }
    });
};

export const setOpenColapse = (valor) => (dispatch, getState) => {
    dispatch({
        type: SET_OPENCOLAPSE,
        payload: {
            valor: valor
        }
    });
};

export const StyledMenu = styled((props) => (
    <Menu
        elevation={0}
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
        }}
        transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
        }}
        {...props}
    />
))(({ theme }) => ({
    '& .MuiPaper-root': {
        borderRadius: 6,
        marginTop: theme.spacing(1),
        minWidth: 230,
        color:
            theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
        boxShadow:
            'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
        '& .MuiMenu-list': {
            padding: '4px 0',
        },
        '& .MuiMenuItem-root': {
            height: 45,
            '& .MuiSvgIcon-root': {
                fontSize: 18,
                color: theme.palette.text.secondary,
                marginRight: theme.spacing(1.5),
            },
            '&:active': {
                backgroundColor: alpha(
                    theme.palette.primary.main,
                    theme.palette.action.selectedOpacity,
                ),
            },
        },
    },
}));

const obtenerNombreModificado = (nombreCompleto) => {
    const palabras = nombreCompleto.split(' ');
    if (palabras.length === 2) {
        return palabras[0];
    } else if (palabras.length === 3) {
        return palabras[0];
    } else if (palabras.length >= 4) {
        return palabras[0] + ' ' + palabras[1];
    } else {
        return nombreCompleto;
    };
};

export const exportarAExcel = (apiData, fileName) => {
    const ws = XLSX.utils.aoa_to_sheet(apiData);
    // Estilos
    const estiloEncabezado = {
        font: {
            sz: 28,
            color: { rgb: "8B4513" }
        },
        fill: {
            patternType: "solid",
            fgColor: { rgb: "f1c761" }
        },
        alignment: {
            vertical: "center",
            horizontal: "left",
            wrapText: true,
            indent: 1
        }
    };
    const estiloSubencabezado = {
        font: {
            sz: 15,
            color: { rgb: "FFFFFF" }
        },
        fill: {
            patternType: "solid",
            fgColor: { rgb: "275953" }
        },
        alignment: {
            vertical: "center",
            horizontal: "left"
        }
    };
    const estiloNormal = {
        font: { color: { rgb: "000000" } },
        fill: { patternType: "solid", fgColor: { rgb: "E9EBF5" } },
        alignment: {
            vertical: 'center',
            horizontal: 'left',
        },
    };
    const estiloUltimaFila = {
        font: {
            color: { rgb: "FFFFFF" },
            bold: true,
            sz: 14
        },
        fill: { patternType: "solid", fgColor: { rgb: "275953" } },
        alignment: { vertical: "center" }
    };
    // Fusionar celdas A1, B1 y C1
    ws['!merges'] = [
        { s: { r: 0, c: 0 }, e: { r: 0, c: 2 } }
    ];
    // Aplicar estilos
    const range = XLSX.utils.decode_range(ws['!ref']);
    for (let row = range.s.r; row <= range.e.r; row++) {
        for (let col = range.s.c; col <= range.e.c; col++) {
            const cellAddress = XLSX.utils.encode_cell({ r: row, c: col });
            if (!ws[cellAddress]) continue;

            if (row === 0 && col === 0) {
                ws[cellAddress].s = estiloEncabezado;
            } else if (row === 1) {
                ws[cellAddress].s = estiloSubencabezado;
            } else if (row === range.e.r) {
                ws[cellAddress].s = estiloUltimaFila;
            } else {
                ws[cellAddress].s = estiloNormal;
            }
        }
    };
    // Ajustar alturas
    if (!ws['!rows']) ws['!rows'] = [];
    ws['!rows'][0] = { hpt: 60 }; // Primera fila (triple altura)
    ws['!rows'][range.e.r] = { hpt: 40 }; // Última fila (doble altura)  
    // Ajustar el ancho de las columnas
    if (!ws['!cols']) ws['!cols'] = [];
    ws['!cols'][0] = { wpx: 450 }; // Columna A: 450px
    ws['!cols'][1] = { wpx: 125 };  // Columna B: 125px
    ws['!cols'][2] = { wpx: 125 };  // Columna C: 125px  
    // Crear el libro y escribir el archivo
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "data");
    XLSX.writeFile(wb, fileName + ".xlsx");
};

export const exportarAExcel2 = (apiData, fileName) => {
    const ws = XLSX.utils.aoa_to_sheet(apiData);
    // Estilos (los mismos que en exportarAExcel)
    const estiloEncabezado = {
        font: {
            sz: 28,
            color: { rgb: "8B4513" }
        },
        fill: {
            patternType: "solid",
            fgColor: { rgb: "f1c761" }
        },
        alignment: {
            vertical: "center",
            horizontal: "left",
            wrapText: true,
            indent: 1
        }
    };
    const estiloSubencabezado = {
        font: {
            sz: 15,
            color: { rgb: "FFFFFF" }
        },
        fill: {
            patternType: "solid",
            fgColor: { rgb: "275953" }
        },
        alignment: {
            vertical: "center",
            horizontal: "left"
        }
    };
    const estiloNormal = {
        font: { color: { rgb: "000000" } },
        fill: { patternType: "solid", fgColor: { rgb: "E9EBF5" } },
        alignment: {
            vertical: 'center',
            horizontal: 'left',
        },
    };
    const estiloUltimaFila = {
        font: {
            color: { rgb: "FFFFFF" },
            bold: true,
            sz: 14
        },
        fill: { patternType: "solid", fgColor: { rgb: "275953" } },
        alignment: { vertical: "center" }
    };
    // Fusionar celdas A1 hasta E1 (5 columnas)
    ws['!merges'] = [
        { s: { r: 0, c: 0 }, e: { r: 0, c: 4 } }
    ];
    // Aplicar estilos
    const range = XLSX.utils.decode_range(ws['!ref']);
    for (let row = range.s.r; row <= range.e.r; row++) {
        for (let col = range.s.c; col <= range.e.c; col++) {
            const cellAddress = XLSX.utils.encode_cell({ r: row, c: col });
            if (!ws[cellAddress]) continue;
            if (row === 0 && col === 0) {
                ws[cellAddress].s = estiloEncabezado;
            } else if (row === 1) {
                ws[cellAddress].s = estiloSubencabezado;
            } else if (row === range.e.r) {
                ws[cellAddress].s = estiloUltimaFila;
            } else {
                ws[cellAddress].s = estiloNormal;
            }
        }
    };
    // Ajustar alturas
    if (!ws['!rows']) ws['!rows'] = [];
    ws['!rows'][0] = { hpt: 60 }; // Primera fila (triple altura)
    ws['!rows'][range.e.r] = { hpt: 40 }; // Última fila (doble altura)  
    // Ajustar el ancho de las columnas para 5 columnas
    if (!ws['!cols']) ws['!cols'] = [];
    ws['!cols'][0] = { wpx: 450 }; // Columna A (Nombre): 450px
    ws['!cols'][1] = { wpx: 125 }; // Columna B (H. Objectiu): 125px
    ws['!cols'][2] = { wpx: 125 }; // Columna C (H. Reals): 125px
    ws['!cols'][3] = { wpx: 125 }; // Columna D (H. Extra): 125px
    ws['!cols'][4] = { wpx: 125 }; // Columna E (Preu): 125px
    // Crear el libro y escribir el archivo
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "data");
    XLSX.writeFile(wb, fileName + ".xlsx");
};

export const generaArchivoXLSRegistresAccion = (mes, total, quantitat, restaurant) => (dispatch, getState) => {
    const { arrayData } = getState().variablesRegistres;
    const elListadoRegistresImprimir = [[`LLISTAT PROPINES Casa Amàlia - ${restaurant === "concepcio" ? "Concepció" : "Portvell"} - ${mes}`], ["NOM", "PUNTUACIÓ", "QUANTITAT"]];
    const registrosOrdenados = arrayData
        .map((registre) => [
            //obtenerNombreModificado(registre.nombreEmpleado),
            registre.nombreEmpleado,
            registre.puntuacion,
            `${registre.quantitat}€`
        ])
        .sort((a, b) => a[0].localeCompare(b[0]));
    registrosOrdenados.forEach((registro) => {
        elListadoRegistresImprimir.push(registro);
    });
    elListadoRegistresImprimir.push(["", "", ""], ["TOTAL", total, `${quantitat}€`]);
    exportarAExcel(elListadoRegistresImprimir, `llistat_propines_${restaurant}_${mes}`);
};

export const generaArchivoXLSHoresExtraAccion = (mes, total) => (dispatch, getState) => {
    const { arrayDataHoresExtra } = getState().variablesHoresExtra;
    const elListadoHoresExtraImprimir = [
        [`LLISTAT HORES EXTRA Casa Amàlia - ${mes}`],
        ['NOM', 'H. OBJECTIU', 'H. REALS', 'H. EXTRA', 'PREU'],
    ];
    const registrosOrdenados = arrayDataHoresExtra
        .map((registre) => [
            //obtenerNombreModificado(registre.nombreEmpleado),
            registre.nombreEmpleado,
            registre.horesObj,
            registre.horesReals,
            registre.horesExtra,
            `${registre.preu}€`,
        ])
        .sort((a, b) => a[0].localeCompare(b[0]));
    registrosOrdenados.forEach((registro) => {
        elListadoHoresExtraImprimir.push(registro);
    });
    elListadoHoresExtraImprimir.push(
        ['', '', '', '', ''],
        ['TOTAL', '', '', total, `${(total * preuHoraExtra).toFixed(2)}€`]
    );
    exportarAExcel2(elListadoHoresExtraImprimir, `llistat_hores_extra_${mes}`);
};

export const Transition = forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});