import axios from 'axios';
import Constantes from '../constantes';

//constantes
const rutaApi = Constantes.RUTA_API;

const dataInicial = {
  loadingAppHoresExtra: false,
  exitoActualizacionHoresExtra: false,
  historicHoresExtra: null,
  errorDeCargaHoresExtra: false,
  arrayDataHoresExtra: null,
  dataCarregadaHoresExtra: {
    estado: false,
    fecha: null,
    quantitat: null,
    total: null,
    id: null,
  },
  exitoEliminarHoresExtra: false,
};

//types
const LOADING_APP_HORESEXTRA = 'LOADING_APP_HORESEXTRAP';
const RESETEA_EXITO_HORESEXTRA = 'RESETEA_EXITO_HORESEXTR';
const OBTENER_HISTORICHORESEXTRAEXITO = 'OBTENER_HISTORICHORESEXTRAEXITO';
const ERROR_DE_CARGA_HORESEXTRA = 'ERROR_DE_CARGA_HORESEXTRAS';
const ACTUALIZAR_HORESEXTRA_EXITO = 'ACTUALIZAR_HORESEXTRA_EXITO';
const SET_DATA_HORESEXTRA = 'SET_DATA_HORESEXTRA';
const SET_DATA_CARREGAT_HORESEXTRA = 'ET_DATA_CARREGAT_HORESEXTRA';
const RESETEA_DATACARREGADAHORESEXTRA = 'RESETEA_DATACARREGADAHORESEXTRA';
const ELIMINAR_HORESEXTRA_EXITO = 'ELIMINAR_HORESEXTRA_EXITO';
const RESETEA_EXITO_REGISTRAR_HORESEXTRA = 'RESETEA_EXITO_REGISTRAR_HORESEXTRA';

//reducer
export default function horesExtraReducer(state = dataInicial, action) {
  switch (action.type) {
    case LOADING_APP_HORESEXTRA:
      return { ...state, loadingAppHoresExtra: true };
    case ERROR_DE_CARGA_HORESEXTRA:
      return {
        ...state,
        errorDeCargaHoresExtra: true,
        loadingAppHoresExtra: false,
      };
    case RESETEA_EXITO_HORESEXTRA:
      return {
        ...state,
        exitoActualizacionHoresExtra: false,
        loadingAppHoresExtra: false,
        historicHoresExtra: null,
        arrayDataHoresExtra: null,
        exitoEliminarHoresExtra: false,
      };
    case OBTENER_HISTORICHORESEXTRAEXITO:
      return {
        ...state,
        historicHoresExtra: action.payload.array,
        errorDeCargaHoresExtra: false,
        loadingAppHoresExtra: false,
      };
    case ACTUALIZAR_HORESEXTRA_EXITO:
      return {
        ...state,
        errorDeCargaHoresExtra: false,
        exitoActualizacionHoresExtra: true,
      };
    case SET_DATA_HORESEXTRA:
      return { ...state, arrayDataHoresExtra: action.payload.arr };
    case SET_DATA_CARREGAT_HORESEXTRA:
      return {
        ...state,
        arrayDataHoresExtra: action.payload.arr,
        loadingAppHoresExtra: false,
        dataCarregadaHoresExtra: {
          estado: true,
          quantitat: action.payload.quantitat,
          fecha: action.payload.fecha,
          total: action.payload.total,
          id: action.payload.id,
        },
      };
    case RESETEA_DATACARREGADAHORESEXTRA:
      return {
        ...state,
        dataCarregadaHoresExtra: {
          estado: false,
          fecha: null,
          quantitat: null,
          total: null,
          id: null,
        },
        arrayDataHoresExtra: null,
      };
    case ELIMINAR_HORESEXTRA_EXITO:
      return { ...state, exitoEliminarHoresExtra: true };
    case RESETEA_EXITO_REGISTRAR_HORESEXTRA:
      return {
        ...state,
        exitoActualizacionHoresExtra: false,
        loadingAppHoresExtra: false,
        historicHoresExtra: null,
        exitoEliminarHoresExtra: false,
      };
    default:
      return { ...state };
  }
}

//acciones

export const resetHoresExtraGeneralMenu = () => (dispatch, getState) => {
  dispatch({
    type: RESETEA_DATACARREGADAHORESEXTRA,
  });
  dispatch({
    type: RESETEA_EXITO_HORESEXTRA,
  });
};

export const resetDataCarregadaHoresExtraAccion =
  () => (dispatch, getState) => {
    dispatch({
      type: RESETEA_DATACARREGADAHORESEXTRA,
    });
  };

export const setDataHoresExtraAccion = (array) => (dispatch, getState) => {
  dispatch({
    type: SET_DATA_HORESEXTRA,
    payload: {
      arr: array,
    },
  });
};

export const reseteaExitoHoresExtraAccion = () => (dispatch, getState) => {
  dispatch({
    type: RESETEA_EXITO_HORESEXTRA,
  });
};

export const reseteaExitoRegistrarHoresExtraAccion =
  () => (dispatch, getState) => {
    dispatch({
      type: RESETEA_EXITO_REGISTRAR_HORESEXTRA,
    });
  };

export const obtenerHoresExtraAccion =
  (objeto) => async (dispatch, getState) => {
    dispatch({
      type: LOADING_APP_HORESEXTRA,
    });
    try {
      const formData = new FormData();
      formData.append('objeto', objeto);
      let apiUrl = rutaApi + 'listar.php';
      const res = await axios.post(apiUrl, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      dispatch({
        type: OBTENER_HISTORICHORESEXTRAEXITO,
        payload: {
          array: res.data,
        },
      });
    } catch (error) {
      dispatch({
        type: ERROR_DE_CARGA_HORESEXTRA,
      });
    }
  };

export const registrarHoresExtraAccion =
  (objeto, datos) => async (dispatch, getState) => {
    dispatch({
      type: LOADING_APP_HORESEXTRA,
    });
    try {
      const losDatos = JSON.stringify(datos);
      const formData = new FormData();
      formData.append('objeto', objeto);
      formData.append('datos', losDatos);
      let apiUrl = rutaApi + 'registrar.php';
      const res = await axios.post(apiUrl, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      dispatch({
        type: ACTUALIZAR_HORESEXTRA_EXITO,
      });
    } catch (error) {
      dispatch({
        type: ERROR_DE_CARGA_HORESEXTRA,
      });
    }
  };

export const actualitzarHoresExtraAccion =
  (objeto, datos, id) => async (dispatch, getState) => {
    dispatch({
      type: LOADING_APP_HORESEXTRA,
    });
    try {
      const losDatos = JSON.stringify(datos);
      const formData = new FormData();
      formData.append('objeto', objeto);
      formData.append('datos', losDatos);
      formData.append('id', id);
      let apiUrl = rutaApi + 'actualizar.php';
      const res = await axios.post(apiUrl, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      dispatch({
        type: ACTUALIZAR_HORESEXTRA_EXITO,
      });
    } catch (error) {
      dispatch({
        type: ERROR_DE_CARGA_HORESEXTRA,
      });
    }
  };

export const obtenirHoresExtraAccion =
  (objeto, id) => async (dispatch, getState) => {
    dispatch({
      type: LOADING_APP_HORESEXTRA,
    });
    try {
      const formData = new FormData();
      formData.append('objeto', objeto);
      formData.append('id', id);
      let apiUrl = rutaApi + 'obtener.php';
      const res = await axios.post(apiUrl, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      dispatch({
        type: SET_DATA_CARREGAT_HORESEXTRA,
        payload: {
          arr: JSON.parse(res.data.resultat_calculs),
          fecha: res.data.fecha,
          total: res.data.total,
          id: res.data.id,
        },
      });
    } catch (error) {
      dispatch({
        type: ERROR_DE_CARGA_HORESEXTRA,
      });
    }
  };

export const eliminarHoresExtraAccion =
  (objeto, id) => async (dispatch, getState) => {
    dispatch({
      type: LOADING_APP_HORESEXTRA,
    });
    try {
      const formData = new FormData();
      formData.append('objeto', objeto);
      formData.append('id', id);
      let apiUrl = rutaApi + 'eliminar.php';
      await axios.post(apiUrl, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      dispatch({
        type: ELIMINAR_HORESEXTRA_EXITO,
      });
    } catch (error) {
      dispatch({
        type: ERROR_DE_CARGA_HORESEXTRA,
      });
    }
  };