import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useIntersection from "../useIntersection";
import { withRouter } from "react-router-dom";
import {
    Box,
    Grid,
    Typography,
    CircularProgress,
    Backdrop,
    Snackbar,
    Stack,
    useMediaQuery,
    TextField,
    Button,
    Input,
    Chip,
    Avatar,
    Paper,
    TableContainer,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    TableFooter,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
    MenuItem,
    Divider,
    InputAdornment,
    Dialog
} from '@mui/material';
import TextSnippetIcon from '@mui/icons-material/TextSnippet';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import ContentPasteSearchIcon from '@mui/icons-material/ContentPasteSearch';
import CancelIcon from '@mui/icons-material/Cancel';
import SaveIcon from '@mui/icons-material/Save';
import { ca } from 'date-fns/locale';
import DateFnsUtils from '@mui/lab/AdapterDateFns';
import { LocalizationProvider, DatePicker } from '@mui/lab';
import * as XLSX from 'xlsx-js-style';

//carga componentes
import HistoricRegistres from './HistoricRegistres';
import CustomDialog from './CustomDialog';

//estilos
import Clases from "../clases";

//importaciones acciones
import {
    obtenerRegistresAccion,
    reseteaExitoAccion,
    registrarRegistreAccion,
    actualitzarRegistreAccion,
    setDataAccion,
    resetDataCarregadaRegistreAccion,
    convertirStringAMesAnio,
    reseteaExitoRegistrarAccion,
    setRestaurantAccion,
} from '../redux/registresDucks';
import {
    setOnEstemAccion,
    setAlertaAccion,
    setOpenDialog,
    setCloseDialog,
    Alert,
    StyledMenu,
    generaArchivoXLSRegistresAccion,
    Transition
} from '../redux/appDucks';

const getHeightScrollable = () => (window.innerHeight - 100) || (document.documentElement.clientHeight - 100) || (document.body.clientHeight - 100);
const getWidthScrollable = () => (window.innerWidth - 240) || (document.documentElement.clientWidth - 240) || (document.body.clientWidth - 240);

const Registres = (props) => {
    const classes = Clases();
    const dispatch = useDispatch();
    const view = useRef();
    const inViewport = useIntersection(view, "0px");
    // if (inViewport.estado && !inViewport.completado) {
    //     window.location.reload(false);
    // };
    const { activo: logged, usuarioActivo: { nombre: usuari, rol } } = useSelector(store => store.variablesUsuario);
    const {
        loadingApp: openLoading,
        historicRegistres,
        errorDeCargaRegistres,
        exitoActualizacionRegistres,
        arrayData,
        dataCarregadaRegistre,
        exitoEliminarRegistre,
        restaurant
    } = useSelector(store => store.variablesRegistres);
    const {
        alerta,
    } = useSelector(store => store.variablesApp);

    //refs
    const fileInputRef = useRef(null);

    //states
    const [heightScrollable, setHeightScrollable] = useState(getHeightScrollable());
    const [widthScrollable, setWidthScrollable] = useState(getWidthScrollable());
    const [openSnack, setOpenSnack] = useState(false);
    const [alert, setAlert] = useState({});
    const breakpoints = {
        xs: useMediaQuery(theme => theme.breakpoints.only('xs')),
        sm: useMediaQuery(theme => theme.breakpoints.only('sm')),
        md: useMediaQuery(theme => theme.breakpoints.only('md')),
        lg: useMediaQuery(theme => theme.breakpoints.only('lg')),
        xl: useMediaQuery(theme => theme.breakpoints.only('xl'))
    };
    const currentDate = new Date();
    const adjustMonth = (date, monthAdjustment) => {
        const year = date.getFullYear();
        const month = date.getMonth();
        const newDate = new Date(year, month + monthAdjustment, 1);
        return newDate;
    };
    const [valueDatePicker, setValueDatePicker] = useState(adjustMonth(currentDate, -1));
    const [total, setTotal] = useState(0);
    const [quantitat, setQuantitat] = useState("");
    const [fileName, setFileName] = useState('Pujar arxiu Excel');
    const columns = [
        { id: 'nombreEmpleado', label: 'Nom', minWidth: 150, align: 'left' },
        { id: 'puntuacion', label: 'Puntuació', minWidth: 60, align: 'right' },
        { id: 'quantitat', label: 'Quantitat', minWidth: 60, align: 'right' },
    ];
    const [anchorEl, setAnchorEl] = useState(null);
    const openMenu = Boolean(anchorEl);
    const [openDialogQuantitat, setOpenDialogQuantitat] = useState(false);
    const [excelData, setExcelData] = useState(null);


    //useEffect

    useEffect(() => {
        if (!logged) {
            props.history.push('/login')
        }
    }, [logged, props.history]);

    useEffect(() => {
        if (rol !== "admin") {
            props.history.push('/')
        }
    }, [rol, props.history]);

    useEffect(() => {
        if (inViewport.estado && !inViewport.completado) {
            window.location.reload(false);
        };
    }, [inViewport]);

    useEffect(() => {
        if (breakpoints.xs || breakpoints.sm || breakpoints.md || breakpoints.lg) {
            document.body.classList.add(classes.conScroll);
        } else {
            document.body.classList.add(classes.sinScroll);
        };
    }, []);

    useEffect(() => {
        dispatch(setOnEstemAccion('registres'));
        const resizeListener = () => {
            setHeightScrollable(getHeightScrollable());
            setWidthScrollable(getWidthScrollable());
        };
        window.addEventListener('resize', resizeListener);
        return () => {
            window.removeEventListener('resize', resizeListener);
        }
    }, []);

    useEffect(() => {
        if (!historicRegistres) {
            dispatch(obtenerRegistresAccion('registres'));
        };
    }, [historicRegistres]);

    useEffect(() => {
        if (alerta.abierto) {
            setAlert({
                mensaje: alerta.mensaje,
                tipo: alerta.tipo
            })
            setOpenSnack(true);
        }
    }, [alerta]);

    useEffect(() => {
        if (errorDeCargaRegistres) {
            dispatch(setAlertaAccion({
                abierto: true,
                mensaje: "Error de connexió amb la base de dades.",
                tipo: 'error'
            }));
        }
    }, [errorDeCargaRegistres]);

    useEffect(() => {
        if (exitoActualizacionRegistres) {
            dispatch(setAlertaAccion({
                abierto: true,
                mensaje: "Registres actualitzats correctament.",
                tipo: 'success'
            }));
            dispatch(reseteaExitoRegistrarAccion());
            // setTotal(0);
            // setQuantitat("");
            // setFileName('Pujar arxiu Excel');
        }
    }, [exitoActualizacionRegistres]);

    useEffect(() => {
        if (exitoEliminarRegistre) {
            dispatch(setAlertaAccion({
                abierto: true,
                mensaje: "Registre eliminat correctament.",
                tipo: 'success'
            }));
            dispatch(reseteaExitoAccion());
            setTotal(0);
            setQuantitat("");
            setFileName('Pujar arxiu Excel');
        }
    }, [exitoEliminarRegistre]);

    useEffect(() => {
        if (dataCarregadaRegistre.estado) {
            setTotal(dataCarregadaRegistre.total);
            setQuantitat(dataCarregadaRegistre.quantitat);
            dispatch(setRestaurantAccion(dataCarregadaRegistre.restaurant));
        };
    }, [dataCarregadaRegistre]);

    //funciones   

    const handleCloseSnack = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        };
        setOpenSnack(false);
        dispatch(setAlertaAccion({
            abierto: false,
            mensaje: '',
            tipo: ''
        }));
    };

    const handleFileUpload = (e) => {
        const file = e.target.files[0];
        if (dataCarregadaRegistre.estado) {
            handleClickReset();
        }
        const validExtension = /\.xlsx$/i;
        if (!validExtension.test(file.name)) {
            dispatch(setAlertaAccion({
                abierto: true,
                mensaje: "Format d'arxiu erroni. Ha de ser un arxiu Excel.",
                tipo: 'error'
            }));
            return;
        }
        setFileName(file.name);
        const reader = new FileReader();
        reader.onload = (event) => {
            const arrayBuffer = event.target.result;
            const workbook = XLSX.read(arrayBuffer, { type: 'array' });
            const sheetName = "Ficha empleados";
            if (workbook.SheetNames.includes(sheetName)) {
                const sheet = workbook.Sheets[sheetName];
                const excelDataPrev = XLSX.utils.sheet_to_csv(sheet);
                if (excelDataPrev) {
                    setOpenDialogQuantitat(true);
                    setExcelData(excelDataPrev);
                };
            } else {
                dispatch(setAlertaAccion({
                    abierto: true,
                    mensaje: "Error al processar arxiu Excel. Format incompatible.",
                    tipo: 'error'
                }));
            }
        };
        reader.readAsArrayBuffer(file);
        handleCloseMenu();
    };

    const actualitzarTotal = (arrDades) => {
        const sumatorioPuntuaciones = (arrDades.reduce((total, empleado) => total + parseFloat(empleado.puntuacion), 0)).toFixed(2);
        setTotal(sumatorioPuntuaciones);
        return parseFloat(sumatorioPuntuaciones);
    };

    const processExcelData = (csvData) => {
        const rows = csvData.split('\n').map(row => row.split(','));
        const parsedData = [];
        let currentEmployee = null;
        rows.forEach(row => {
            if (row[0] && !row[1]) {
                // Detectar nombre del empleado
                if (currentEmployee) {
                    parsedData.push(currentEmployee);
                }
                currentEmployee = { name: row[0], details: [] };
            } else if (currentEmployee) {
                // Agregar detalles al empleado actual
                currentEmployee.details.push(row);
            }
        });
        if (currentEmployee) {
            parsedData.push(currentEmployee);
        }
        const employeeData = getEmployeeData(parsedData);
        const employeeDataPuntos = employeeData.map(empleado => {
            return {
                nombreEmpleado: empleado.name,
                puntuacion: restaurant === "concepcio" ? calculateScoreConcepcio(empleado.horario) : calculateScorePortvell(empleado.horario)
            };
        });
        const totalPuntuaciones = actualitzarTotal(employeeDataPuntos);
        const employeeDataConQuantitat = employeeDataPuntos.map(empleado => {
            return {
                ...empleado,
                quantitat: ((empleado.puntuacion * quantitat) / totalPuntuaciones).toFixed(2)
            };
        });
        dispatch(setDataAccion(employeeDataConQuantitat));
    };

    const getEmployeeData = (data) => {
        const employees = [];
        let currentEmployee = null;
        data.forEach(entry => {
            if (entry.name.startsWith("Total periodo")) {
                return;
            } else if (!entry.name.startsWith("Semana")) {
                currentEmployee = {
                    name: entry.name,
                    horario: []
                };
                employees.push(currentEmployee);
            } else if (currentEmployee && entry.name.startsWith("Semana")) {
                entry.details.forEach(day => {
                    if (day[4] && day[5] && day[4].trim() && day[5].trim()) {
                        currentEmployee.horario.push({
                            dia: day[0],
                            inicio: day[4],
                            fin: day[5]
                        });
                    }
                });
            }
        });
        return employees.filter(employee => employee.horario.length > 0);
    };

    const calculateScoreConcepcio = (horario) => {
        let puntuacionTotal = 0;
        // Definimos los intervalos de comprobación con sus puntuaciones y duraciones
        const cuartaComprobacion = {
            inicio: 780,
            fin: 990,
            total: 210,
            puntuacion: 0.9,
            nombre: '13:00-16:30'
        };
        const quintaComprobacion = {
            inicio: 990,
            fin: 1140,
            total: 150,
            puntuacion: 0.15,
            nombre: '16:30-19:00'
        };
        const sextaComprobacion = {
            inicio: 1140,
            fin: 1440,
            total: 300,
            puntuacion: 0.95,
            nombre: '19:00-00:00'
        };
        if (!Array.isArray(horario)) {
            console.error('Error: El horario debe ser un array');
            return '0.00';
        }
        horario.forEach(({ dia, inicio, fin }, index) => {
            if (!inicio?.includes('h') || !fin?.includes('h')) {
                console.error(`Error en registro ${index}: Formato de hora inválido`);
                return;
            }
            // Convertimos inicio y fin a minutos desde medianoche
            const [startHour, startMinute] = inicio.split('h').map(Number);
            const [initEndHour, endMinute] = fin.split('h').map(Number);
            if (isNaN(startHour) || isNaN(startMinute) || isNaN(initEndHour) || isNaN(endMinute)) {
                console.error(`Error en registro ${index}: Valores de hora no válidos`);
                return;
            }
            const endHour = initEndHour < 4 ? initEndHour + 24 : initEndHour;
            const startTotalMinutes = startHour * 60 + startMinute;
            const endTotalMinutes = endHour * 60 + endMinute;
            console.log(`\nProcesando registro ${index + 1}:`);
            console.log(`Hora inicio: ${inicio} (${startTotalMinutes} minutos)`);
            console.log(`Hora fin: ${fin} (${endTotalMinutes} minutos)`);
            const applyComprobacion = (comprobacion) => {
                if (!comprobacion || comprobacion.total <= 0) return;
                // Calculamos el solapamiento
                const overlapStart = Math.max(startTotalMinutes, comprobacion.inicio);
                const overlapEnd = Math.min(endTotalMinutes, comprobacion.fin);
                const overlap = overlapEnd - overlapStart;
                if (overlap > 0) {
                    // Calculamos la proporción del intervalo cubierto
                    const proporcion = overlap / comprobacion.total;
                    const puntuacionParcial = proporcion * comprobacion.puntuacion;
                    puntuacionTotal += puntuacionParcial;
                    if (overlap === comprobacion.total) {
                        console.log(`✓ Intervalo ${comprobacion.nombre}: Suma completa ${comprobacion.puntuacion}`);
                    } else {
                        console.log(`↦ Intervalo ${comprobacion.nombre}: Suma parcial ${puntuacionParcial.toFixed(4)} (${(proporcion * 100).toFixed(1)}%)`);
                    }
                }
            };
            // Aplicamos las comprobaciones en orden
            applyComprobacion(cuartaComprobacion);
            applyComprobacion(quintaComprobacion);
            applyComprobacion(sextaComprobacion);
        });
        console.log(`\nPuntuación total: ${puntuacionTotal.toFixed(2)}`);
        return puntuacionTotal.toFixed(2);
    };

    const calculateScorePortvell = (horario) => {
        let puntuacionTotal = 0;
        const segundaComprobacion = {
            inicio: 750,
            fin: 1020,
            total: 270,  
            puntuacion: 0.95,
            nombre: '12:30-17:00'
        };
        const terceraComprobacion = {
            inicio: 1020,
            fin: 1140,
            total: 120,  
            puntuacion: 0.15,
            nombre: '17:00-19:00'
        };
        const cuartaComprobacion = {
            inicio: 1140,
            fin: 1380,
            total: 240, 
            puntuacion: 0.90,
            nombre: '19:00-23:00'
        };
        if (!Array.isArray(horario)) {
            console.error('Error: El horario debe ser un array');
            return '0.00';
        }
        horario.forEach(({ dia, inicio, fin }, index) => {
            if (!inicio?.includes('h') || !fin?.includes('h')) {
                console.error(`Error en registro ${index}: Formato de hora inválido`);
                return;
            }
            // Convertimos inicio y fin a minutos desde medianoche
            const [startHour, startMinute] = inicio.split('h').map(Number);
            const [initEndHour, endMinute] = fin.split('h').map(Number);
            if (isNaN(startHour) || isNaN(startMinute) || isNaN(initEndHour) || isNaN(endMinute)) {
                console.error(`Error en registro ${index}: Valores de hora no válidos`);
                return;
            }
            const endHour = initEndHour < 4 ? initEndHour + 24 : initEndHour;
            const startTotalMinutes = startHour * 60 + startMinute;
            const endTotalMinutes = endHour * 60 + endMinute;
            console.log(`\nProcesando registro ${index + 1}:`);
            console.log(`Hora inicio: ${inicio} (${startTotalMinutes} minutos)`);
            console.log(`Hora fin: ${fin} (${endTotalMinutes} minutos)`);
            const applyComprobacion = (comprobacion) => {
                if (!comprobacion || comprobacion.total <= 0) return;
                // Calculamos el solapamiento
                const overlapStart = Math.max(startTotalMinutes, comprobacion.inicio);
                const overlapEnd = Math.min(endTotalMinutes, comprobacion.fin);
                const overlap = overlapEnd - overlapStart;
                if (overlap > 0) {
                    // Calculamos la proporción del intervalo cubierto
                    const proporcion = overlap / comprobacion.total;
                    const puntuacionParcial = proporcion * comprobacion.puntuacion;
                    puntuacionTotal += puntuacionParcial;
                    if (overlap === comprobacion.total) {
                        console.log(`✓ Intervalo ${comprobacion.nombre}: Suma completa ${comprobacion.puntuacion}`);
                    } else {
                        console.log(`↦ Intervalo ${comprobacion.nombre}: Suma parcial ${puntuacionParcial.toFixed(4)} (${(proporcion * 100).toFixed(1)}%)`);
                    }
                }
            };
            applyComprobacion(segundaComprobacion);
            applyComprobacion(terceraComprobacion);
            applyComprobacion(cuartaComprobacion);
        });
        return puntuacionTotal.toFixed(2);
    };

    const handleButtonClick = async (restaurant) => {
        dispatch(setRestaurantAccion(restaurant));
        if (fileInputRef.current) {
            fileInputRef.current.value = null;
        }
        fileInputRef.current.click();
    };

    const handleClickRegistrar = () => {
        const fecha = `${valueDatePicker.getFullYear()}-${valueDatePicker.getMonth() + 1}`;
        const idRegistre = historicRegistres.find(item => item.fecha === fecha && item.restaurant === restaurant)?.id || null;
        const registreAGuardar = {
            fecha,
            quantitat,
            resultat_calculs: JSON.stringify(arrayData),
            total,
            usuari,
            restaurant
        };
        if (idRegistre) {
            dispatch(
                setOpenDialog({
                    children: (
                        <>
                            <DialogTitle>Registre existent</DialogTitle>
                            <DialogContent>
                                <DialogContentText>
                                    <span>{`Ja hi ha un registre corresponent al mes ${fecha} a la base de dades. Vols substituir-lo?`}</span>
                                </DialogContentText>
                            </DialogContent>
                            <DialogActions>
                                <Button
                                    onClick={() => {
                                        dispatch(setCloseDialog());
                                    }}
                                    color="secondary"
                                    autoFocus
                                >
                                    No
                                </Button>
                                <Button
                                    onClick={() => {
                                        dispatch(setCloseDialog());
                                        dispatch(actualitzarRegistreAccion('registres', registreAGuardar, idRegistre));
                                    }}
                                    color="secondary"
                                    autoFocus
                                >
                                    Sí
                                </Button>
                            </DialogActions>
                        </>
                    ),
                })
            );
            return;
        };
        dispatch(registrarRegistreAccion('registres', registreAGuardar));
        handleCloseMenu();
    };

    const handleClickReset = () => {
        dispatch(resetDataCarregadaRegistreAccion());
        setTotal(0);
        setQuantitat("");
        setFileName('Pujar arxiu Excel');
        handleCloseMenu();
    };

    const handleClickMenu = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleCloseMenu = () => {
        setAnchorEl(null);
    };

    const handleCloseDialogQuantitat = (registrat) => {
        if (registrat && quantitat > 0) {
            processExcelData(excelData);
            setOpenDialogQuantitat(false);
            setExcelData(null);
        } else {
            dispatch(setAlertaAccion({
                abierto: true,
                mensaje: "S'ha de registrar la quantitat a repartir.",
                tipo: 'error'
            }));
            return;
        };
    };

    const handleGenerarXLS = () => {
        const mes = dataCarregadaRegistre.estado ? dataCarregadaRegistre.fecha : `${valueDatePicker.getFullYear()}-${valueDatePicker.getMonth() + 1}`;
        const quantitatAPassar = dataCarregadaRegistre.estado ? dataCarregadaRegistre.quantitat : quantitat;
        const totalAPassar = dataCarregadaRegistre.estado ? dataCarregadaRegistre.total : total;
        dispatch(generaArchivoXLSRegistresAccion(mes, totalAPassar, quantitatAPassar, restaurant));
        handleCloseMenu();
    };

    return (
        <div>
            <Backdrop className={classes.loading} open={openLoading}>
                <CircularProgress color="inherit" />
            </Backdrop>
            {historicRegistres?.length >= 0 && (
                <Grid
                    spacing={1}
                    container
                    direction="row"
                    justifycontent="flex-start"
                    alignItems="flex-start"
                    ref={view}
                >
                    <Box
                        sx={{
                            display: "flex",
                            flexDirection: {
                                xs: 'column',
                                sm: 'column',
                                md: 'column',
                                lg: 'row',
                                xl: 'row',
                            },
                            justifyContent: {
                                xs: 'flex-start',
                                sm: 'flex-start',
                                md: 'flex-start',
                                lg: 'space-between',
                                xl: 'space-between',
                            },
                            width: "100%",
                            alignItems: {
                                xs: 'flex-start',
                                sm: 'flex-start',
                                md: 'flex-start',
                                lg: 'center',
                                xl: 'center',
                            },
                            pt: 2,
                            px: 2
                        }}>
                        <Chip sx={{
                            width: {
                                xs: '100%',
                                sm: '100%',
                                md: '100%',
                                lg: 'auto',
                                xl: 'auto'
                            },
                            justifyContent: "flex-start"
                        }} label="Contador hores propines Casa Amàlia" avatar={<Avatar>1</Avatar>} />
                        <Stack
                            direction={{
                                xs: 'column',
                                sm: 'column',
                                md: 'column',
                                lg: 'row',
                                xl: 'row',
                            }}
                            alignItems={{
                                xs: 'flex-start',
                                sm: 'flex-start',
                                md: 'flex-start',
                                lg: 'center',
                                xl: 'center',
                            }}
                            justifyContent={{
                                xs: 'flex-start',
                                sm: 'flex-start',
                                md: 'flex-start',
                                lg: 'center',
                                xl: 'center',
                            }}
                            spacing={2}
                            sx={{
                                marginTop: {
                                    xs: 2,
                                    sm: 2,
                                    md: 2,
                                    lg: 0,
                                    xl: 0,
                                },
                                width: {
                                    xs: '100%',
                                    sm: '100%',
                                    md: '100%',
                                    lg: 'auto',
                                    xl: 'auto',
                                },
                                '& > *': {
                                    width: {
                                        xs: '100%',
                                        sm: '100%',
                                        md: '100%',
                                        lg: 'auto',
                                        xl: 'auto',
                                    },
                                },
                            }}
                        >
                            <LocalizationProvider dateAdapter={DateFnsUtils} locale={ca}>
                                <DatePicker
                                    size="small"
                                    views={['year', 'month']}
                                    inputVariant="outlined"
                                    fullWidth
                                    format="MM/yyyy"
                                    label="Mes a gestionar"
                                    minDate={adjustMonth(currentDate, -4)}
                                    maxDate={adjustMonth(currentDate, 4)}
                                    value={valueDatePicker}
                                    onChange={setValueDatePicker}
                                    renderInput={(params) => <TextField {...params} />}
                                />
                            </LocalizationProvider>
                            <Button
                                sx={{ mb: 0.5, px: 4, py: 1.6, minWidth: 230 }}
                                variant="contained"
                                color='primary'
                                size="large"
                                startIcon={<ContentPasteSearchIcon />}
                                onClick={handleClickMenu}
                            >
                                Contador Hores
                            </Button>
                            <StyledMenu
                                anchorEl={anchorEl}
                                open={openMenu}
                                onClose={handleCloseMenu}
                            >
                                <Input
                                    type="file"
                                    accept=".xlsx"
                                    onChange={handleFileUpload}
                                    inputRef={fileInputRef}
                                    style={{ display: 'none' }}
                                />
                                <MenuItem
                                    onClick={() => handleButtonClick("concepcio")}
                                    disableRipple
                                >
                                    <UploadFileIcon />
                                    Pujar arxiu /concepcio
                                </MenuItem>
                                <MenuItem
                                    onClick={() => handleButtonClick("portvell")}
                                    disableRipple
                                >
                                    <UploadFileIcon />
                                    Pujar arxiu /portvell
                                </MenuItem>
                                <Divider sx={{ my: 0.5 }} />
                                <MenuItem
                                    onClick={handleClickRegistrar}
                                    disableRipple
                                    disabled={dataCarregadaRegistre.estado || !arrayData}
                                >
                                    <SaveIcon />
                                    Registrar dades
                                </MenuItem>
                                <MenuItem
                                    onClick={handleGenerarXLS}
                                    disableRipple
                                    disabled={!dataCarregadaRegistre.estado && !arrayData}
                                >
                                    <TextSnippetIcon />
                                    Generar resultat Excel
                                </MenuItem>
                                <MenuItem
                                    onClick={handleClickReset}
                                    disableRipple
                                    disabled={!dataCarregadaRegistre.estado && !arrayData}
                                >
                                    <CancelIcon />
                                    Reset dades
                                </MenuItem>
                            </StyledMenu>
                        </Stack>
                    </Box>
                    <Grid
                        spacing={2}
                        container
                        direction="row"
                        justifycontent="flex-start"
                        alignItems="flex-start"
                        p={2}
                    >
                        <Grid item xs={12} sm={12} md={12} lg={6} xl={8}>
                            <Box
                                mt={2}
                                color="secondary.contrastText"
                                bgcolor={dataCarregadaRegistre.estado ? "#618BF1" : "secondary.main"}
                                className={classes.boxStl}
                                sx={{ width: '100%', display: "flex", justifyContent: "space-between", alignItems: "center", height: 50 }}
                            >
                                <Stack direction={'row'} spacing={1} alignItems="center" sx={{ width: "100%" }}>
                                    <TextSnippetIcon sx={{ marginTop: -0.3 }} />
                                    <Typography
                                        variant="body2"
                                        noWrap
                                    >
                                        {dataCarregadaRegistre.estado ? `Registre: ${dispatch(convertirStringAMesAnio(dataCarregadaRegistre.fecha))} Restaurant: ${dataCarregadaRegistre.restaurant === "concepcio" ? "Concepció" : "Port Vell"}` : `Arxiu Excel a gestionar: ${fileName}`}
                                    </Typography>
                                </Stack>
                            </Box >
                            <Box mt={2} pr={{ xs: 1, sm: 1, md: 1, lg: 1 }} className={classes.scrollable}
                                sx={{
                                    height: {
                                        xs: 'auto',
                                        sm: 'auto',
                                        md: 'auto',
                                        lg: heightScrollable - 160,
                                        xl: heightScrollable - 160
                                    },
                                }}>
                                <Paper sx={{ width: '100%', overflow: 'hidden' }}>
                                    <TableContainer sx={{ marginBottom: 4 }}>
                                        <Table>
                                            <TableHead>
                                                <TableRow>
                                                    {columns.map((column) => (
                                                        <TableCell
                                                            key={column.id}
                                                            align={column.align}
                                                            style={{ minWidth: column.minWidth }}
                                                        >
                                                            {column.label}
                                                        </TableCell>
                                                    ))}
                                                </TableRow>
                                            </TableHead>
                                            {arrayData && (
                                                <TableBody>
                                                    {arrayData
                                                        .map((row, index) => {
                                                            return (
                                                                <TableRow hover tabIndex={-1} key={`col-${index}`}>
                                                                    {columns.map((column, index1) => (
                                                                        <TableCell
                                                                            key={`row-${index1}`}
                                                                            align={column.align}
                                                                        >
                                                                            {column.id === "quantitat" ? `${row[column.id]}€` : row[column.id]}
                                                                        </TableCell>
                                                                    ))}
                                                                </TableRow>
                                                            );
                                                        })}
                                                </TableBody>
                                            )}
                                            <TableFooter>
                                                <TableRow >
                                                    <TableCell></TableCell>
                                                    <TableCell></TableCell>
                                                    <TableCell></TableCell>
                                                </TableRow>
                                                <TableRow sx={{ backgroundColor: "#f5f5f5" }}>
                                                    <TableCell>
                                                        <Typography
                                                            component="span"
                                                            color="text.primary"
                                                        >
                                                            Total
                                                        </Typography>
                                                    </TableCell>
                                                    <TableCell sx={{ textAlign: "right" }}>
                                                        <Typography
                                                            component="span"
                                                            color="text.primary"
                                                        >
                                                            {total}
                                                        </Typography>
                                                    </TableCell>
                                                    <TableCell sx={{ textAlign: "right" }}>
                                                        <Typography
                                                            component="span"
                                                            color="text.primary"
                                                        >
                                                            {`${openDialogQuantitat ? 0 : quantitat || 0}€`}
                                                        </Typography>
                                                    </TableCell>
                                                </TableRow>
                                            </TableFooter>
                                        </Table>
                                    </TableContainer>
                                </Paper>
                            </Box>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={6} xl={4}>
                            <HistoricRegistres
                                prHeight={heightScrollable}
                                prWidth={widthScrollable}
                                historicRegistres={historicRegistres}
                                breakpoints={breakpoints}
                                dataCarregadaRegistre={dataCarregadaRegistre}
                                setTotal={setTotal}
                            />
                        </Grid>
                    </Grid>
                </Grid>
            )}
            <Snackbar open={openSnack} autoHideDuration={12000} onClose={handleCloseSnack}>
                <Alert severity={alert.tipo} onClose={handleCloseSnack}>
                    {alert.mensaje}
                </Alert>
            </Snackbar>
            <CustomDialog />
            <Dialog
                open={openDialogQuantitat}
                TransitionComponent={Transition}
                onClose={() => handleCloseDialogQuantitat(false)}
                PaperProps={{
                    component: 'form',
                    onSubmit: (event) => {
                        event.preventDefault();
                        handleCloseDialogQuantitat(true);
                    },
                }}
            >
                <DialogTitle>Quantitat a repartir</DialogTitle>
                <DialogContent>
                    <TextField
                        autoFocus
                        fullWidth
                        variant="outlined"
                        type='number'
                        value={quantitat}
                        onChange={(e) => setQuantitat(e.target.value)}
                        InputProps={{
                            endAdornment: <InputAdornment position="end">€</InputAdornment>,
                        }}
                    />
                </DialogContent>
                <DialogActions>
                    <Button
                        color="secondary"
                        type="submit"
                        disabled={!quantitat}
                    >
                        Registrar
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    )
}

export default withRouter(Registres)