import axios from 'axios';
import Constantes from "../constantes";

//constantes
const rutaApi = Constantes.RUTA_API;

const dataInicial = {
    loadingAppResseyes: false,
    arrayComptes: [],
    errorDeCargaComptes: false,
    exitoActualizacionCompte: false,
    arrayRessenyes: [],
    errorDeCargaRessenyes: false,
    exitoActualizacionRessenya: false,
    ressenyesAGestionar: [],
    historicRessenyes: []
}

//types
const LOADING_APP_RESSENYES = 'LOADING_APP_RESSENYES';
const OBTENER_COMPTES_EXITO = 'OBTENER_COMPTES_EXITO';
const ERROR_DE_CARGA_COMPTES = 'ERROR_DE_CARGA_COMPTES';
const ACTUALIZAR_COMPTE_EXITO = 'ACTUALIZAR_COMPTE_EXITO';
const RESETEA_EXITO_RESSENYES = 'RESETEA_EXITO_RESSENYES';
const OBTENER_RESSENYES_EXITO = 'OBTENER_RESSENYES_EXITO';
const ERROR_DE_CARGA_RESSENYES = 'ERROR_DE_CARGA_RESSENYES';
const ACTUALIZAR_RESSENYA_EXITO = 'ACTUALIZAR_RESSENYA_EXITO';
const SET_RESSENYESAGESTIONAR = 'SET_RESSENYESAGESTIONAR';
const SET_HISTORICRESSENYES = 'SET_HISTORICRESSENYES';

//reducer
export default function ressenyesReducer(state = dataInicial, action) {
    switch (action.type) {
        case LOADING_APP_RESSENYES:
            return { ...state, loadingAppResseyes: true }
        case OBTENER_COMPTES_EXITO:
            return { ...state, arrayComptes: action.payload.array, errorDeCargaComptes: action.payload.errorDeCargaComptes, loadingAppResseyes: false }
        case ERROR_DE_CARGA_COMPTES:
            return { ...state, errorDeCargaComptes: true, loadingAppResseyes: false }
        case ACTUALIZAR_COMPTE_EXITO:
            return { ...state, errorDeCargaComptes: false, exitoActualizacionCompte: true }
        case RESETEA_EXITO_RESSENYES:
            return { ...state, exitoActualizacionCompte: false, exitoActualizacionRessenya: false }
        case OBTENER_RESSENYES_EXITO:
            return { ...state, arrayRessenyes: action.payload.array, errorDeCargaRessenyes: action.payload.errorDeCargaRessenyes, loadingAppResseyes: false }
        case ERROR_DE_CARGA_RESSENYES:
            return { ...state, errorDeCargaRessenyes: true, loadingAppResseyes: false }
        case ACTUALIZAR_RESSENYA_EXITO:
            return { ...state, errorDeCargaRessenyes: false, exitoActualizacionRessenya: true }
        case SET_RESSENYESAGESTIONAR:
            return { ...state, ressenyesAGestionar: action.payload.array }     
        case SET_HISTORICRESSENYES:
            return { ...state, historicRessenyes: action.payload.array }     
        default:
            return { ...state }
    }
}

//acciones

export const resetRessenyesGeneralMenu = () => (dispatch, getState) => {
    dispatch({
        type: RESETEA_EXITO_RESSENYES
    });
    dispatch({
        type: SET_HISTORICRESSENYES,
        payload: {
            array: []
        }
    });
    dispatch({
        type: SET_RESSENYESAGESTIONAR,
        payload: {
            array: []
        }
    });
};

export const setHistoricRessenyesAccion = (array) => (dispatch, getState) => {
    dispatch({
        type: SET_HISTORICRESSENYES,
        payload: {
            array: array
        }
    });
};

export const setRessenyesAGestionarAccion = (array) => (dispatch, getState) => {
    dispatch({
        type: SET_RESSENYESAGESTIONAR,
        payload: {
            array: array
        }
    });
};

export const obtenerComptesAccion = (objeto) => async (dispatch, getState) => {
    dispatch({
        type: LOADING_APP_RESSENYES
    });
    try {
        const formData = new FormData();
        formData.append("objeto", objeto);
        let apiUrl = rutaApi + "listar.php";
        const res = await axios.post(apiUrl, formData, {
            headers: {
                "Content-Type": "multipart/form-data",
            }
        });
        const respuesta = res.data;    
        respuesta.sort((a, b) => a.nom.localeCompare(b.nom));
        dispatch({
            type: OBTENER_COMPTES_EXITO,
            payload: {
                array: respuesta,
                errorDeCargaComptes: false
            }
        })
    } catch (error) {
        dispatch({
            type: ERROR_DE_CARGA_COMPTES
        })
    }
};

export const actualizarCompteAccion = (objeto, id, estado) => async (dispatch, getState) => {
    dispatch({
        type: LOADING_APP_RESSENYES
    });
    let elEstado;
    if (estado) {
        elEstado = 'si';
    } else {
        elEstado = 'no';
    };
    try {
        const formData = new FormData();
        formData.append("objeto", objeto);
        formData.append("id", id);
        formData.append("estado", elEstado);
        let apiUrl = rutaApi + "actualizar.php";
        await axios.post(apiUrl, formData, {
            headers: {
                "Content-Type": "multipart/form-data",
            }
        });
        dispatch({
            type: ACTUALIZAR_COMPTE_EXITO
        });
    } catch (error) {
        dispatch({
            type: ERROR_DE_CARGA_COMPTES
        })
    }
};

export const reseteaExitoRessenyesAccion = () => (dispatch, getState) => {
    dispatch({
        type: RESETEA_EXITO_RESSENYES
    });
};

export const obtenerRessenyesAccion = (objeto) => async (dispatch, getState) => {
    dispatch({
        type: LOADING_APP_RESSENYES
    });
    try {
        const formData = new FormData();
        formData.append("objeto", objeto);
        let apiUrl = rutaApi + "listar.php";
        const res = await axios.post(apiUrl, formData, {
            headers: {
                "Content-Type": "multipart/form-data",
            }
        });
        const respuesta = res.data;
        dispatch({
            type: OBTENER_RESSENYES_EXITO,
            payload: {
                array: respuesta,
                errorDeCargaRessenyes: false
            }
        })
    } catch (error) {
        dispatch({
            type: ERROR_DE_CARGA_RESSENYES
        })
    }
};

export const actualizarRessenyaAccion = (objeto, id, datos) => async (dispatch, getState) => {
    dispatch({
        type: LOADING_APP_RESSENYES
    });
    try {
        const losDatos = JSON.stringify(datos);
        const formData = new FormData();
        formData.append("objeto", objeto);
        formData.append("id", id);
        formData.append("datos", losDatos);
        let apiUrl = rutaApi + "actualizar.php";
        await axios.post(apiUrl, formData, {
            headers: {
                "Content-Type": "multipart/form-data",
            }
        });
        dispatch({
            type: ACTUALIZAR_RESSENYA_EXITO
        });
    } catch (error) {
        dispatch({
            type: ERROR_DE_CARGA_RESSENYES
        })
    }
};