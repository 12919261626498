import axios from 'axios';
import Constantes from "../constantes";

//constantes
const rutaApi = Constantes.RUTA_API;

const dataInicial = {
    loadingApp: false,
    exitoActualizacionRegistres: false,
    historicRegistres: null,
    errorDeCargaRegistres: false,
    arrayData: null,
    dataCarregadaRegistre: { estado: false, fecha: null, quantitat: null, restaurant: null, total: null, id: null },
    exitoEliminarRegistre: false,
    restaurant: null
}

//types
const LOADING_APP = 'LOADING_APP';
const RESETEA_EXITO = 'RESETEA_EXITO';
const OBTENER_HISTORICREGISTRESEXITO = 'OBTENER_HISTORICREGISTRESEXITO';
const ERROR_DE_CARGA_REGISTRES = 'ERROR_DE_CARGA_REGISTRES';
const ACTUALIZAR_REGISTRES_EXITO = 'ACTUALIZAR_REGISTRES_EXITO';
const SET_DATA = 'SET_DATA';
const SET_DATA_CARREGAT = 'SET_DATA_CARREGAT';
const RESETEA_DATACARREGADAREGISTRE = 'RESETEA_DATACARREGADAREGISTRE';
const ELIMINAR_REGISTRE_EXITO = 'ELIMINAR_REGISTRE_EXITO';
const RESETEA_EXITO_REGISTRAR = 'RESETEA_EXITO_REGISTRAR';
const SET_RESTAURANT = 'SET_RESTAURANT';

//reducer
export default function registresReducer(state = dataInicial, action) {
    switch (action.type) {
        case LOADING_APP:
            return { ...state, loadingApp: true }
        case ERROR_DE_CARGA_REGISTRES:
            return { ...state, errorDeCargaRegistres: true, loadingApp: false }
        case RESETEA_EXITO:
            return { ...state, exitoActualizacionRegistres: false, loadingApp: false, historicRegistres: null, arrayData: null, exitoEliminarRegistre: false }
        case OBTENER_HISTORICREGISTRESEXITO:
            return { ...state, historicRegistres: action.payload.array, errorDeCargaRegistres: false, loadingApp: false }
        case ACTUALIZAR_REGISTRES_EXITO:
            return { ...state, errorDeCargaRegistres: false, exitoActualizacionRegistres: true }
        case SET_DATA:
            return { ...state, arrayData: action.payload.arr }
        case SET_DATA_CARREGAT:
            return { ...state, arrayData: action.payload.arr, loadingApp: false, dataCarregadaRegistre: { estado: true, quantitat: action.payload.quantitat, restaurant: action.payload.restaurant, fecha: action.payload.fecha, total: action.payload.total, id: action.payload.id } }
        case RESETEA_DATACARREGADAREGISTRE:
            return { ...state, dataCarregadaRegistre: { estado: false, fecha: null, quantitat: null, total: null, id: null }, arrayData: null }
        case ELIMINAR_REGISTRE_EXITO:
            return { ...state, exitoEliminarRegistre: true }
        case RESETEA_EXITO_REGISTRAR:
            return { ...state, exitoActualizacionRegistres: false, loadingApp: false, historicRegistres: null, exitoEliminarRegistre: false, restaurant: null }
        case SET_RESTAURANT:
            return { ...state, restaurant: action.payload.valor }
        default:
            return { ...state }
    }
}

//acciones

export const setRestaurantAccion = (valor) => (dispatch, getState) => {
    dispatch({
        type: SET_RESTAURANT,
        payload: {
            valor: valor
        }
    });
};

export const resetRegistresGeneralMenu = () => (dispatch, getState) => {
    dispatch({
        type: RESETEA_DATACARREGADAREGISTRE
    });
    dispatch({
        type: RESETEA_EXITO
    });
};

export const convertirStringAMesAnio = (fechaStr) => (dispatch, getState) => {
    const [anio, mes] = fechaStr.split('-');
    const meses = ['Gener', 'Febrer', 'Març', 'Abril', 'Maig', 'Juny', 'Juliol', 'Agost', 'Setembre', 'Octubre', 'Novembre', 'Desembre'];
    const nombreMes = meses[parseInt(mes) - 1];
    const fechaFormateada = `${nombreMes} del ${anio}`;
    return fechaFormateada;
}

export const resetDataCarregadaRegistreAccion = () => (dispatch, getState) => {
    dispatch({
        type: RESETEA_DATACARREGADAREGISTRE
    });
};

export const setDataAccion = (array) => (dispatch, getState) => {
    dispatch({
        type: SET_DATA,
        payload: {
            arr: array
        }
    });
};

export const reseteaExitoAccion = () => (dispatch, getState) => {
    dispatch({
        type: RESETEA_EXITO
    });
};

export const reseteaExitoRegistrarAccion = () => (dispatch, getState) => {
    dispatch({
        type: RESETEA_EXITO_REGISTRAR
    });
};

export const obtenerRegistresAccion = (objeto) => async (dispatch, getState) => {
    dispatch({
        type: LOADING_APP
    });
    try {
        const formData = new FormData();
        formData.append("objeto", objeto);
        let apiUrl = rutaApi + "listar.php";
        const res = await axios.post(apiUrl, formData, {
            headers: {
                "Content-Type": "multipart/form-data",
            }
        });
        dispatch({
            type: OBTENER_HISTORICREGISTRESEXITO,
            payload: {
                array: res.data
            }
        })
    } catch (error) {
        dispatch({
            type: ERROR_DE_CARGA_REGISTRES
        })
    }
};

export const registrarRegistreAccion = (objeto, datos) => async (dispatch, getState) => {
    dispatch({
        type: LOADING_APP
    });
    try {
        const losDatos = JSON.stringify(datos);
        const formData = new FormData();
        formData.append("objeto", objeto);
        formData.append("datos", losDatos);
        let apiUrl = rutaApi + "registrar.php";
        const res = await axios.post(apiUrl, formData, {
            headers: {
                "Content-Type": "multipart/form-data",
            }
        });
        dispatch({
            type: ACTUALIZAR_REGISTRES_EXITO
        });
    } catch (error) {
        dispatch({
            type: ERROR_DE_CARGA_REGISTRES
        })
    }
};

export const actualitzarRegistreAccion = (objeto, datos, id) => async (dispatch, getState) => {
    dispatch({
        type: LOADING_APP
    });
    try {
        const losDatos = JSON.stringify(datos);
        const formData = new FormData();
        formData.append("objeto", objeto);
        formData.append("datos", losDatos);
        formData.append("id", id);
        let apiUrl = rutaApi + "actualizar.php";
        const res = await axios.post(apiUrl, formData, {
            headers: {
                "Content-Type": "multipart/form-data",
            }
        });
        dispatch({
            type: ACTUALIZAR_REGISTRES_EXITO
        });
    } catch (error) {
        dispatch({
            type: ERROR_DE_CARGA_REGISTRES
        })
    }
};

export const obtenirRegistreAccion = (objeto, id) => async (dispatch, getState) => {
    dispatch({
        type: LOADING_APP
    });
    try {
        const formData = new FormData();
        formData.append("objeto", objeto);
        formData.append("id", id);
        let apiUrl = rutaApi + "obtener.php";
        const res = await axios.post(apiUrl, formData, {
            headers: {
                "Content-Type": "multipart/form-data",
            }
        });        
        dispatch({
            type: SET_DATA_CARREGAT,
            payload: {
                arr: JSON.parse(res.data.resultat_calculs),
                fecha: res.data.fecha,
                quantitat: res.data.quantitat,
                restaurant: res.data.restaurant,
                total: res.data.total,
                id: res.data.id
            }
        });
    } catch (error) {
        dispatch({
            type: ERROR_DE_CARGA_REGISTRES
        })
    }
};

export const eliminarRegistreAccion = (objeto, id) => async (dispatch, getState) => {
    dispatch({
        type: LOADING_APP
    });
    try {
        const formData = new FormData();
        formData.append("objeto", objeto);
        formData.append("id", id);
        let apiUrl = rutaApi + "eliminar.php";
        await axios.post(apiUrl, formData, {
            headers: {
                "Content-Type": "multipart/form-data",
            }
        });
        dispatch({
            type: ELIMINAR_REGISTRE_EXITO
        });
    } catch (error) {
        dispatch({
            type: ERROR_DE_CARGA_REGISTRES
        })
    }
};