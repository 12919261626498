import React from 'react';
import { useDispatch } from 'react-redux';
import {
    AppBar,
    Toolbar,
    Box,
    Typography,
    IconButton,
    Hidden
} from '@mui/material';
import logo from '../images/logo_header.png';
import MenuIcon from '@mui/icons-material/Menu';

//estilos
import Clases from "../clases";

//importaciones acciones
import { setCurrentDateAccion } from '../redux/appDucks';

const Navbar = (props) => {
    const classes = Clases();
    const dispatch = useDispatch();

    //useEffect

    return (
        <AppBar className={classes.appBar}>
            <Toolbar>
                <IconButton
                    color="inherit"
                    aria-label="menu"
                    className={classes.menuButton}
                    onClick={() => props.accionAbrir()}
                >
                    <MenuIcon />
                </IconButton>
                <Hidden only={'xs'}>
                    <Box className={classes.fonsLogo}>
                        <img src={logo} className={classes.logo} alt="logo" />
                    </Box>
                    <Typography className={classes.title} variant="h5">
                        Eines de Gestió Casa Amàlia
                    </Typography>
                    <Typography variant="body1" color="inherit" component="div">
                        {dispatch(setCurrentDateAccion())}
                    </Typography>
                </Hidden>
            </Toolbar>
        </AppBar>
    )
}

export default Navbar;