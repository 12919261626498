import React, { Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    List,
    ListItemIcon,
    ListItemText,
    Divider,
    ListSubheader,
    ListItemButton,
    Collapse
} from '@mui/material';
import RateReviewIcon from '@mui/icons-material/RateReview';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import ContentPasteSearchIcon from '@mui/icons-material/ContentPasteSearch';
import RedeemIcon from '@mui/icons-material/Redeem';
import HomeIcon from '@mui/icons-material/Home';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import BorderColorIcon from '@mui/icons-material/BorderColor';
import { withRouter } from "react-router-dom";

//carga componentes

//importaciones acciones
import { logoutUsuarioAccion } from '../redux/usuarioDucks';
import { resetRegistresGeneralMenu } from '../redux/registresDucks';
import { resetRessenyesGeneralMenu } from '../redux/ressenyesDucks';
import { resetRegalaGeneralMenu } from '../redux/regalaDucks';
import { setOpenColapse } from '../redux/appDucks';
import { resetHoresExtraGeneralMenu } from '../redux/horesExtraDucks';

const Menu = (props) => {
    const dispatch = useDispatch();
    const { activo: logged, usuarioActivo: { nombre: usuari, rol } } = useSelector(store => store.variablesUsuario);
    const {
        onEstem,
        openColapse
    } = useSelector(store => store.variablesApp);

    //states       

    //funciones

    const tancarSessio = () => {
        dispatch(logoutUsuarioAccion());
        localStorage.clear();
        props.history.push('/login');
    };

    const handleClickCollapse = () => {
        dispatch(setOpenColapse(!openColapse));
    };

    const handleClick = (link) => {
        switch (link) {
            case '/':
                props.history.push('/');
                dispatch(resetRegistresGeneralMenu());
                dispatch(resetRessenyesGeneralMenu());
                dispatch(resetRegalaGeneralMenu());
                dispatch(resetHoresExtraGeneralMenu());
                break;
            case '/registres':
                props.history.push('/registres');
                dispatch(resetRessenyesGeneralMenu());
                dispatch(resetRegalaGeneralMenu());
                dispatch(resetHoresExtraGeneralMenu());
                break;
            case '/ressenyes':
                props.history.push('/ressenyes');
                dispatch(resetRegistresGeneralMenu());
                dispatch(resetRegalaGeneralMenu());
                dispatch(resetHoresExtraGeneralMenu());
                break;
            case '/horesExtra':
                props.history.push('/horesExtra');
                dispatch(resetRegistresGeneralMenu());
                dispatch(resetRegalaGeneralMenu());
                dispatch(resetRessenyesGeneralMenu());
                break;
            case '/comptes':
                props.history.push('/comptes');
                dispatch(resetRegistresGeneralMenu());
                dispatch(resetRegalaGeneralMenu());
                dispatch(resetHoresExtraGeneralMenu());
                break;
            case '/regala':
                props.history.push('/regala');
                dispatch(resetRegistresGeneralMenu());
                dispatch(resetRessenyesGeneralMenu());
                dispatch(resetHoresExtraGeneralMenu());
                break;
            default:
        }
    };

    return (
        <div>
            {/* {console.log(rol)} */}
            {logged ? (
                <List component='nav'
                    subheader={
                        <ListSubheader component="div">
                            Eines de Gestió
                        </ListSubheader>
                    }>
                    <Fragment>
                        <ListItemButton
                            disabled={onEstem === 'inici'}
                            onClick={() => handleClick('/')}
                            style={{ marginTop: -3 }}
                        >
                            <ListItemIcon>
                                <HomeIcon />
                            </ListItemIcon>
                            <ListItemText primary='Inici' />
                        </ListItemButton>
                        {rol === "admin" && (
                            <ListItemButton
                                disabled={onEstem === 'registres'}
                                onClick={() => handleClick('/registres')}
                                style={{ marginTop: -3 }}
                            >
                                <ListItemIcon>
                                    <ContentPasteSearchIcon />
                                </ListItemIcon>
                                <ListItemText primary='Contador propines' />
                            </ListItemButton>
                        )}
                        {rol === "admin" && (
                            <ListItemButton
                                disabled={onEstem === 'horesExtra'}
                                onClick={() => handleClick('/horesExtra')}
                                style={{ marginTop: -3 }}
                            >
                                <ListItemIcon>
                                    <ContentPasteSearchIcon />
                                </ListItemIcon>
                                <ListItemText primary="Contador H. extra" />
                            </ListItemButton>
                        )}
                        <ListItemButton
                            disabled={onEstem === 'regala' || rol !== "admin"}
                            onClick={() => handleClick('/regala')}
                            style={{ marginTop: -3 }}
                        >
                            <ListItemIcon>
                                <RedeemIcon />
                            </ListItemIcon>
                            <ListItemText primary='Gestió Regala' />
                        </ListItemButton>
                        {rol === "admin" && (
                            <>
                                <ListItemButton
                                    disabled={rol !== "admin"}
                                    onClick={handleClickCollapse}
                                >
                                    <ListItemIcon>
                                        <RateReviewIcon />
                                    </ListItemIcon>
                                    <ListItemText primary="Ressenyes" />
                                    {openColapse ? <ExpandLess /> : <ExpandMore />}
                                </ListItemButton>
                                <Collapse in={openColapse} timeout="auto" unmountOnExit>
                                    <List component="div" disablePadding>
                                        <ListItemButton
                                            disabled={onEstem === 'ressenyes'}
                                            sx={{ pl: 4 }}
                                            onClick={() => handleClick('/ressenyes')}
                                        >
                                            <ListItemIcon>
                                                <BorderColorIcon />
                                            </ListItemIcon>
                                            <ListItemText primary="Editar" />
                                        </ListItemButton>
                                        <ListItemButton
                                            disabled={onEstem === 'comptes'}
                                            sx={{ pl: 4 }}
                                            onClick={() => handleClick('/comptes')}
                                        >
                                            <ListItemIcon>
                                                <AccountCircleIcon />
                                            </ListItemIcon>
                                            <ListItemText primary="Comptes" />
                                        </ListItemButton>
                                    </List>
                                </Collapse>
                            </>
                        )}
                        <Divider />
                    </Fragment>
                </List>
            ) : null}
            <List component='nav'>
                <ListItemButton
                    onClick={tancarSessio}
                >
                    <ListItemIcon>
                        <LockOpenIcon />
                    </ListItemIcon>
                    <ListItemText primary={logged ? ('Logout') : ('Login')} />
                </ListItemButton>
                <Divider />
            </List>
        </div>
    )
}

export default withRouter(Menu)