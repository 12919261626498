import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import {
    Box,
    Grid,
    Typography,
    InputBase,
    Stack,
    Alert,
    List,
    ListItem,
    ListItemText,
    Paper,
    Tooltip,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
    Button,
} from '@mui/material';
import { styled, alpha } from '@mui/material/styles';
import SearchIcon from '@mui/icons-material/Search';
import EventRepeatIcon from '@mui/icons-material/EventRepeat';
import IconButton from '@mui/material/IconButton';
import PublishIcon from '@mui/icons-material/Publish';
import DeleteIcon from '@mui/icons-material/Delete';

//carga componentes
import GraficHistoricHoresExtra from './GraficHistoricHoresExtra';

//importaciones acciones
import { convertirStringAMesAnio } from '../redux/registresDucks';
import {
    obtenirHoresExtraAccion,
    eliminarHoresExtraAccion,
    resetDataCarregadaHoresExtraAccion,
} from '../redux/horesExtraDucks';
import { setOpenDialog, setCloseDialog } from '../redux/appDucks';

//estilos
import Clases from '../clases';

const Search = styled('div')(({ theme }) => ({
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    '&:hover': {
        backgroundColor: alpha(theme.palette.common.white, 0.25),
    },
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
        marginLeft: theme.spacing(1),
        width: 'auto',
    },
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
    padding: theme.spacing(0, 1),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
    color: 'inherit',
    '& .MuiInputBase-input': {
        padding: theme.spacing(0.3, 0.3, 0.3, 0),
        fontSize: 14,
        // vertical padding + font size from searchIcon
        paddingLeft: `calc(1em + ${theme.spacing(4)})`,
        transition: theme.transitions.create('width'),
        width: '12ch',
        [theme.breakpoints.up('xl')]: {
            width: '12ch',
            '&:focus': {
                width: '20ch',
            },
        },
    },
}));

const HistoricHoresExtra = (props) => {
    const {
        prHeight,
        prWidth,
        historicHoresExtra,
        breakpoints,
        dataCarregadaHoresExtra,
        setTotal,
    } = props;
    const classes = Clases();
    const dispatch = useDispatch();

    //states
    const [cercaActiva, setCercaActiva] = useState(false);
    const [senseResultats, setSenseResultats] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');
    const [arrayHoresExtra, setArrayHoresExtra] = useState(historicHoresExtra);

    //useEffect

    useEffect(() => {
        if (searchTerm !== '') {
            const delayDebounceFn = setTimeout(() => {
                gestionaCerca();
            }, 500);
            return () => clearTimeout(delayDebounceFn);
        } else {
            setArrayHoresExtra(historicHoresExtra);
            setSenseResultats(false);
            setCercaActiva(false);
        }
    }, [searchTerm]);

    //funciones

    const handleClickHoraExtra = (id) => {
        dispatch(obtenirHoresExtraAccion('hores_extra', id));
    };

    const handleClickEliminarHoraExtra = (id, horaExtra) => {
        dispatch(
            setOpenDialog({
                children: (
                    <>
                        <DialogTitle>Eliminar registre</DialogTitle>
                        <DialogContent>
                            <DialogContentText>
                                <span>{`Estàs segur que vols eliminar el registre ${horaExtra} de la base de dades?`}</span>
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button
                                onClick={() => {
                                    dispatch(setCloseDialog());
                                }}
                                color="secondary"
                                autoFocus
                            >
                                No
                            </Button>
                            <Button
                                onClick={() => {
                                    dispatch(setCloseDialog());
                                    dispatch(eliminarHoresExtraAccion('hores_extra', id));
                                    if (dataCarregadaHoresExtra.estado) {
                                        setTotal(0);
                                        dispatch(resetDataCarregadaHoresExtraAccion());
                                    }
                                }}
                                color="secondary"
                                autoFocus
                            >
                                Sí
                            </Button>
                        </DialogActions>
                    </>
                ),
            })
        );
    };

    const gestionaCerca = () => {
        if (!searchTerm) return;
        const cercaMin = searchTerm.toLowerCase();
        const arrayResultat = historicHoresExtra.filter((horaExtra) =>
            dispatch(convertirStringAMesAnio(horaExtra.fecha))
                .toLowerCase()
                .includes(cercaMin)
        );
        setCercaActiva(!!arrayResultat.length);
        setSenseResultats(!arrayResultat.length);
        setArrayHoresExtra(arrayResultat);
    };

    return (
        <div>
            {/* {console.log(dataCarregadaRegistre)} */}
            <Box
                mt={2}
                color="secondary.contrastText"
                bgcolor="secondary.main"
                className={classes.boxStl}
                sx={{ width: '100%', height: 50 }}
            >
                <Grid
                    container
                    sx={{
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        width: '100%',
                    }}
                >
                    <Grid item sx={{ mt: 0.5, width: '45%' }}>
                        <Stack direction={'row'} spacing={1}>
                            <EventRepeatIcon sx={{ marginTop: -0.3 }} />
                            <Typography variant="body2" noWrap>
                                Històric registres
                            </Typography>
                        </Stack>
                    </Grid>
                    <Grid item sx={{ mt: 0.5, mr: 1, width: '45%' }}>
                        <Search>
                            <SearchIconWrapper>
                                <SearchIcon fontSize="small" />
                            </SearchIconWrapper>
                            <StyledInputBase
                                placeholder="Cerca…"
                                inputProps={{ 'aria-label': 'search' }}
                                onChange={(e) => setSearchTerm(e.target.value)}
                            />
                        </Search>
                    </Grid>
                </Grid>
            </Box>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: {
                        xs: 'column',
                        sm: 'column',
                        md: 'row',
                        lg: 'column',
                        xl: 'column',
                    },
                    gap: {
                        xs: 2,
                        sm: 2,
                        md: 2,
                        lg: 0,
                        xl: 0,
                    },
                }}
            >
                <Box
                    mt={2}
                    pr={{ xs: 1, sm: 1, md: 1, lg: 1, xl: 1 }}
                    className={classes.scrollable}
                    sx={{
                        maxHeight: {
                            xs: 330,
                            sm: 330,
                            md: 330,
                            lg: 330,
                            xl: (prHeight - 200) / 2,
                        },
                        width: {
                            xs: '100%',
                            sm: '100%',
                            md: '50%',
                            lg: '100%',
                            xl: '100%',
                        },
                    }}
                >
                    <Paper>
                        <Box sx={{ padding: 2, width: '100%' }}>
                            {senseResultats || arrayHoresExtra.length === 0 ? (
                                <Alert severity="error" sx={{ marginBottom: 1 }}>
                                    No hi ha resultats
                                </Alert>
                            ) : null}
                            <List sx={{ width: '100%' }}>
                                {arrayHoresExtra.map((horaExtra, index) => (
                                    <ListItem
                                        key={`reg-${index}`}
                                        disableGutters
                                        secondaryAction={
                                            <>
                                                <Tooltip
                                                    title={
                                                        horaExtra.id === dataCarregadaHoresExtra.id
                                                            ? ''
                                                            : 'Obtenir registre'
                                                    }
                                                    arrow
                                                    placement="left"
                                                >
                                                    <span>
                                                        <IconButton
                                                            onClick={() => handleClickHoraExtra(horaExtra.id)}
                                                            disabled={
                                                                horaExtra.id === dataCarregadaHoresExtra.id
                                                            }
                                                        >
                                                            <PublishIcon />
                                                        </IconButton>
                                                    </span>
                                                </Tooltip>
                                                <Tooltip
                                                    title="Eliminar registre"
                                                    arrow
                                                    placement="top"
                                                >
                                                    <IconButton
                                                        onClick={() =>
                                                            handleClickEliminarHoraExtra(
                                                                horaExtra.id,
                                                                dispatch(
                                                                    convertirStringAMesAnio(horaExtra.fecha)
                                                                )
                                                            )
                                                        }
                                                    >
                                                        <DeleteIcon color="error" />
                                                    </IconButton>
                                                </Tooltip>
                                            </>
                                        }
                                    >
                                        <ListItemText
                                            secondary={
                                                <Typography
                                                    sx={{
                                                        display: 'inline-block',
                                                        maxWidth: '85%',
                                                        wordWrap: 'break-word',
                                                        wordBreak: 'break-word',
                                                        whiteSpace: 'normal',
                                                        overflowWrap: 'break-word',
                                                        hyphens: 'auto',
                                                    }}
                                                    component="span"
                                                    variant="body2"
                                                    color="text.primary"
                                                >
                                                    {`${dispatch(
                                                        convertirStringAMesAnio(horaExtra.fecha)
                                                    )} - registrat per: ${horaExtra.usuari}`}
                                                </Typography>
                                            }
                                        />
                                    </ListItem>
                                ))}
                            </List>
                        </Box>
                    </Paper>
                </Box>
                <Box
                    mt={{ xs: 2, sm: 2, md: 2, lg: 3, xl: 3 }}
                    pr={{ xs: 1, sm: 1, md: 1, lg: 1, xl: 1 }}
                    sx={{
                        height: {
                            xs: 330,
                            sm: 330,
                            md: 330,
                            lg: 330,
                            xl: (prHeight - 200) / 2,
                        },
                        width: {
                            xs: '100%',
                            sm: '100%',
                            md: '50%',
                            lg: '100%',
                            xl: '100%',
                        },
                    }}
                >
                    <Paper>
                        <GraficHistoricHoresExtra
                            prHeight={prHeight}
                            prWidth={prWidth}
                            historicHoresExtra={historicHoresExtra}
                            breakpoints={breakpoints}
                        />
                    </Paper>
                </Box>
            </Box>
        </div>
    );
};

export default HistoricHoresExtra;