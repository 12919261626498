import React, { useState, useEffect } from 'react';
import {
    BarChart,
    Bar,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip
} from 'recharts';
import { useDispatch } from 'react-redux';
import {
    Grid,
    Box,
    Typography
} from '@mui/material';

//importaciones acciones
import { convertirStringAMesAnio } from '../redux/registresDucks';

const GraficHistoric = (props) => {
    const {
        prHeight,
        prWidth,
        historicRegistres,
        breakpoints,
        restaurant
    } = props;
    const dispatch = useDispatch();
    const xAxisTick = { fontSize: '0.7rem' };
    const yAxisTick = { fontSize: '0.7rem' };

    //states
    const [dataGrafic, setDataGrafic] = useState(null);
    const [yAxisDomain, setYAxisDomain] = useState(null);

    //useEffect

    useEffect(() => {
        if (historicRegistres && restaurant) {
            const registresFiltrats = historicRegistres.filter(registre => registre.restaurant === restaurant);
            const dadesFormatejades = registresFiltrats.slice(0, 12).map(registre => ({
                name: registre.fecha,
                total: registre.total,
                quantitat: registre.quantitat
            }));
            while (dadesFormatejades.length < 12) {
                dadesFormatejades.push({
                    name: "",
                    total: 0,
                    quantitat: "0€"
                });
            }
            setDataGrafic(dadesFormatejades);
            const maxTotal = Math.max(...dadesFormatejades.map(entry => entry.total));
            const roundedMaxTotal = Math.round(maxTotal / 50) * 50;
            setYAxisDomain([0, roundedMaxTotal]);
        }
    }, [historicRegistres, restaurant]);

    //funciones

    const CustomTooltip = ({ active, payload, label }) => {
        if (active && payload && payload.length && label !== "") {
            return (
                <Box sx={{ backgroundColor: "white", p: 2, border: "1px solid #e0e0e0" }}>
                    <Typography
                        component="span"
                        variant="body2"
                        color="text.primary">
                        {`${dispatch(convertirStringAMesAnio(label))}: ${payload[0].value} ${restaurant === "concepcio" ? `punts` : `hores`} - ${payload[1].value}€`}
                    </Typography>
                </Box>
            );
        };
        return null;
    };

    const getChartDimensions = () => {
        const dimensions = {
            md: { width: prWidth * 0.47, height: 330 },
            lg: { width: prWidth * 0.47, height: 330 },
            xl: { width: prWidth * 0.30, height: (prHeight - 200) / 2 },
            xs: { width: prWidth + 150, height: 330 },
            sm: { width: prWidth - 120, height: 330 }
        };
        for (const breakpoint in breakpoints) {
            if (breakpoints[breakpoint]) {
                return dimensions[breakpoint];
            }
        };
    };
    const { width, height } = getChartDimensions();

    if (!dataGrafic) {
        return;
    };

    return (
        <div>
            {/* {console.log(dataGrafic)} */}
            <Grid
                spacing={1}
                container
                direction="column"
                justify="center"
                alignItems="center"
                p={1}
                style={{
                    height: {
                        xs: 330,
                        sm: 330,
                        md: 330,
                        lg: 330,
                        xl: (prHeight - 200) / 2
                    }
                }}
            >
                <BarChart
                    width={width}
                    height={height}
                    data={dataGrafic}
                    margin={{
                        top: 20,
                        right: 30,
                        left: 0,
                        bottom: 15,
                    }}
                >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="name" tick={xAxisTick} />
                    <YAxis yAxisId="left" orientation="left" stroke={restaurant === "concepcio" ? `#618BF1` : `#FF8042`} tick={{ fontSize: 12 }} />
                    <YAxis yAxisId="right" orientation="right" stroke="#82ca9d" tick={{ fontSize: 12 }} tickFormatter={(value) => `${value}€`} />
                    <Tooltip content={<CustomTooltip />} />
                    <Bar yAxisId="left" dataKey="total" fill={restaurant === "concepcio" ? `#618BF1` : `#FF8042`} />
                    <Bar yAxisId="right" dataKey="quantitat" fill="#82ca9d" />
                </BarChart>
            </Grid>
        </div>
    )
}

export default GraficHistoric