import React, { useState, useEffect, useRef } from 'react';
import Constantes from '../constantes';
import { useDispatch, useSelector } from 'react-redux';
import useIntersection from '../useIntersection';
import { withRouter } from 'react-router-dom';
import {
    Box,
    Grid,
    Typography,
    CircularProgress,
    Backdrop,
    Snackbar,
    Stack,
    useMediaQuery,
    TextField,
    Button,
    Input,
    Chip,
    Avatar,
    Paper,
    TableContainer,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    TableFooter,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
    MenuItem,
    Divider,
} from '@mui/material';
import TextSnippetIcon from '@mui/icons-material/TextSnippet';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import ContentPasteSearchIcon from '@mui/icons-material/ContentPasteSearch';
import CancelIcon from '@mui/icons-material/Cancel';
import SaveIcon from '@mui/icons-material/Save';
import { ca } from 'date-fns/locale';
import DateFnsUtils from '@mui/lab/AdapterDateFns';
import { LocalizationProvider, DatePicker } from '@mui/lab';
import * as XLSX from 'xlsx-js-style';

//carga componentes
import HistoricHoresExtra from './HistoricHoresExtra';
import CustomDialog from './CustomDialog';

//estilos
import Clases from '../clases';

//importaciones acciones
import { convertirStringAMesAnio } from '../redux/registresDucks';
import {
    resetDataCarregadaHoresExtraAccion,
    setDataHoresExtraAccion,
    reseteaExitoHoresExtraAccion,
    reseteaExitoRegistrarHoresExtraAccion,
    obtenerHoresExtraAccion,
    registrarHoresExtraAccion,
    actualitzarHoresExtraAccion,
} from '../redux/horesExtraDucks';
import {
    setOnEstemAccion,
    setAlertaAccion,
    setOpenDialog,
    setCloseDialog,
    Alert,
    StyledMenu,
    generaArchivoXLSHoresExtraAccion,
} from '../redux/appDucks';

const getHeightScrollable = () =>
    window.innerHeight - 100 ||
    document.documentElement.clientHeight - 100 ||
    document.body.clientHeight - 100;
const getWidthScrollable = () =>
    window.innerWidth - 240 ||
    document.documentElement.clientWidth - 240 ||
    document.body.clientWidth - 240;

//constantes
const preuHoraExtra = Constantes.PREU_HORA_EXTRA;

const HoresExtra = (props) => {
    const classes = Clases();
    const dispatch = useDispatch();
    const view = useRef();
    const inViewport = useIntersection(view, '0px');
    // if (inViewport.estado && !inViewport.completado) {
    //     window.location.reload(false);
    // };
    const {
        activo: logged,
        usuarioActivo: { nombre: usuari, rol },
    } = useSelector((store) => store.variablesUsuario);
    const {
        loadingAppHoresExtra: openLoading,
        historicHoresExtra,
        errorDeCargaHoresExtra,
        exitoActualizacionHoresExtra,
        arrayDataHoresExtra,
        dataCarregadaHoresExtra,
        exitoEliminarHoresExtra,
    } = useSelector((store) => store.variablesHoresExtra);
    const { alerta } = useSelector((store) => store.variablesApp);

    //refs
    const fileInputRef = useRef(null);

    //states
    const [heightScrollable, setHeightScrollable] = useState(
        getHeightScrollable()
    );
    const [widthScrollable, setWidthScrollable] = useState(getWidthScrollable());
    const [openSnack, setOpenSnack] = useState(false);
    const [alert, setAlert] = useState({});
    const breakpoints = {
        xs: useMediaQuery((theme) => theme.breakpoints.only('xs')),
        sm: useMediaQuery((theme) => theme.breakpoints.only('sm')),
        md: useMediaQuery((theme) => theme.breakpoints.only('md')),
        lg: useMediaQuery((theme) => theme.breakpoints.only('lg')),
        xl: useMediaQuery((theme) => theme.breakpoints.only('xl')),
    };
    const currentDate = new Date();
    const adjustMonth = (date, monthAdjustment) => {
        const year = date.getFullYear();
        const month = date.getMonth();
        const newDate = new Date(year, month + monthAdjustment, 1);
        return newDate;
    };
    const [valueDatePicker, setValueDatePicker] = useState(
        adjustMonth(currentDate, -1)
    );
    const [total, setTotal] = useState(0);
    const [fileName, setFileName] = useState('Pujar arxiu Excel');
    const columns = [
        { id: 'nombreEmpleado', label: 'Nom', minWidth: 100, align: 'left' },
        { id: 'horesObj', label: 'H. objectiu', minWidth: 60, align: 'right' },
        { id: 'horesReals', label: 'H. reals', minWidth: 60, align: 'right' },
        { id: 'horesExtra', label: 'H. extra', minWidth: 60, align: 'right' },
        { id: 'preu', label: 'Preu', minWidth: 60, align: 'right' },
    ];
    const [anchorEl, setAnchorEl] = useState(null);
    const openMenu = Boolean(anchorEl);

    //useEffect

    useEffect(() => {
        if (!logged) {
            props.history.push('/login');
        }
    }, [logged, props.history]);

    useEffect(() => {
        if (rol !== 'admin') {
            props.history.push('/');
        }
    }, [rol, props.history]);

    useEffect(() => {
        if (inViewport.estado && !inViewport.completado) {
            window.location.reload(false);
        }
    }, [inViewport]);

    useEffect(() => {
        if (breakpoints.xs || breakpoints.sm || breakpoints.md || breakpoints.lg) {
            document.body.classList.add(classes.conScroll);
        } else {
            document.body.classList.add(classes.sinScroll);
        }
    }, []);

    useEffect(() => {
        dispatch(setOnEstemAccion('horesExtra'));
        const resizeListener = () => {
            setHeightScrollable(getHeightScrollable());
            setWidthScrollable(getWidthScrollable());
        };
        window.addEventListener('resize', resizeListener);
        return () => {
            window.removeEventListener('resize', resizeListener);
        };
    }, []);

    useEffect(() => {
        if (!historicHoresExtra) {
            dispatch(obtenerHoresExtraAccion('hores_extra'));
        }
    }, [historicHoresExtra]);

    useEffect(() => {
        if (alerta.abierto) {
            setAlert({
                mensaje: alerta.mensaje,
                tipo: alerta.tipo,
            });
            setOpenSnack(true);
        }
    }, [alerta]);

    useEffect(() => {
        if (errorDeCargaHoresExtra) {
            dispatch(
                setAlertaAccion({
                    abierto: true,
                    mensaje: 'Error de connexió amb la base de dades.',
                    tipo: 'error',
                })
            );
        }
    }, [errorDeCargaHoresExtra]);

    useEffect(() => {
        if (exitoActualizacionHoresExtra) {
            dispatch(
                setAlertaAccion({
                    abierto: true,
                    mensaje: 'Registres actualitzats correctament.',
                    tipo: 'success',
                })
            );
            dispatch(reseteaExitoRegistrarHoresExtraAccion());
            setTotal(0);
            setFileName('Pujar arxiu Excel');
        }
    }, [exitoActualizacionHoresExtra]);

    useEffect(() => {
        if (exitoEliminarHoresExtra) {
            dispatch(
                setAlertaAccion({
                    abierto: true,
                    mensaje: 'Registre eliminat correctament.',
                    tipo: 'success',
                })
            );
            dispatch(reseteaExitoHoresExtraAccion());
            setTotal(0);
            setFileName('Pujar arxiu Excel');
        }
    }, [exitoEliminarHoresExtra]);

    useEffect(() => {
        if (dataCarregadaHoresExtra.estado) {
            setTotal(dataCarregadaHoresExtra.total);
        }
    }, [dataCarregadaHoresExtra]);

    //funciones

    const getDaysInCurrentMonth = (month) => {
        const now = new Date();
        const year = now.getFullYear();
        return new Date(year, month, 0).getDate();
    };

    const handleCloseSnack = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenSnack(false);
        dispatch(
            setAlertaAccion({
                abierto: false,
                mensaje: '',
                tipo: '',
            })
        );
    };

    const handleFileUpload = (e) => {
        const file = e.target.files[0];
        if (dataCarregadaHoresExtra.estado) {
            handleClickReset();
        }
        const validExtension = /\.xlsx$/i;
        if (!validExtension.test(file.name)) {
            dispatch(
                setAlertaAccion({
                    abierto: true,
                    mensaje: "Format d'arxiu erroni. Ha de ser un arxiu Excel.",
                    tipo: 'error',
                })
            );
            return;
        }
        setFileName(file.name);
        const reader = new FileReader();
        reader.onload = (event) => {
            const arrayBuffer = event.target.result;
            const workbook = XLSX.read(arrayBuffer, { type: 'array' });
            const sheetName = 'Ficha empleados';
            if (workbook.SheetNames.includes(sheetName)) {
                const sheet = workbook.Sheets[sheetName];
                const excelDataPrev = XLSX.utils.sheet_to_csv(sheet);
                if (excelDataPrev) {
                    processExcelData(excelDataPrev);
                }
            } else {
                dispatch(
                    setAlertaAccion({
                        abierto: true,
                        mensaje: 'Error al processar arxiu Excel. Format incompatible.',
                        tipo: 'error',
                    })
                );
            }
        };
        reader.readAsArrayBuffer(file);
        handleCloseMenu();
    };

    const actualitzarTotal = (arrDades) => {
        const sumatorioHoras = arrDades
            .reduce((total, empleado) => total + parseFloat(empleado.horesExtra), 0)
            .toFixed(2);
        setTotal(sumatorioHoras);
        return parseFloat(sumatorioHoras);
    };

    const processExcelData = (csvData) => {
        const rows = csvData.split('\n').map((row) => row.split(','));
        // primer registre de tota la data Ex: Total periodo (06/05/2024-31/05/2024)
        const diasMesActual = getDaysInCurrentMonth(
            parseInt(
                rows
                    .find((row) => row[0].startsWith('Total periodo'))?.[0]
                    .match(/\((\d{2})\/(\d{2})\/\d{4}-\d{2}\/(\d{2})\/\d{4}\)/)?.[2]
            )
        );
        const parsedData = [];
        let currentEmployee = null;
        rows.forEach((row) => {
            if (row[0] && !row[1]) {
                if (currentEmployee) {
                    parsedData.push(currentEmployee);
                }
                // Crear nuevo empleado
                currentEmployee = {
                    name: row[0],
                    details: [],
                    horasComputables: {
                        volumenHorario: null,
                        diasMesActual: null,
                        totalHoras: null,
                    },
                };
            }
            // Si ya tenemos un empleado actual, procesar los detalles
            if (currentEmployee) {
                if (row[0].startsWith('Semana') && row[3]) {
                    currentEmployee.horasComputables = {
                        volumenHorario: Number(row[3]),
                        diasMesActual,
                        totalHoras: Number(((Number(row[3]) / 7) * diasMesActual).toFixed(2)),
                    };
                }
                if (
                    row[0] &&
                    !row[0].startsWith('Total periodo') &&
                    !row[0].startsWith('Semana')
                ) {
                    currentEmployee.details.push(row);
                }
            }
        });
        if (currentEmployee) {
            parsedData.push(currentEmployee);
        }
        const employeeData = getEmployeeData(parsedData);
        const employeeDataHorasConQuantitat = employeeData
            .map((empleado) => {
                const { horesReals, horesExtra } = calculateScore(empleado.horario);
                return {
                    nombreEmpleado: empleado.name,
                    horesObj: empleado.horario[0].horasComputables.totalHoras,
                    horesReals,
                    horesExtra
                };
            })
            //.filter((empleado) => empleado.horesExtra > 0)
            .map((empleado) => ({
                ...empleado,
                preu: Number((empleado.horesExtra * preuHoraExtra).toFixed(2)),
            }));
        actualitzarTotal(employeeDataHorasConQuantitat);
        dispatch(setDataHoresExtraAccion(employeeDataHorasConQuantitat));
    };

    const getEmployeeData = (data) => {
        const employees = [];
        let currentEmployee = null;
        data.forEach((entry, index) => {
            if (entry.name.startsWith('Total periodo')) {
                return;
            } else if (!entry.name.startsWith('Semana')) {
                currentEmployee = {
                    name: entry.name,
                    horario: [],
                };
                employees.push(currentEmployee);
            } else if (currentEmployee && entry.name.startsWith('Semana')) {
                if (entry?.details.length > 0) {
                    entry.details.forEach((day) => {
                        if (day[4] && day[5] && day[4].trim() && day[5].trim()) {
                            currentEmployee.horario.push({
                                dia: day[0],
                                inicio: day[4],
                                fin: day[5],
                                tipo: 'horario',
                                horasComputables: entry.horasComputables,
                            });
                        } else if (/^\s*(vacaciones|baja por enfermedad)\s*$/i.test(day[1])) {
                            currentEmployee.horario.push({
                                dia: day[0],
                                tipo: 'sinHorario',
                                horasComputables: entry.horasComputables,
                            });
                        }
                    });
                } else {
                    //treballador sense hores
                    currentEmployee.horario.push({
                        tipo: 'sinDatos',
                        horasComputables: entry.horasComputables,
                    });
                }
            }
        });
        return employees;
        //return employees.filter((employee) => employee.horario.length > 0);
    };

    const calculateScore = (horario) => {
        let minutosReales = 0;
        const horasComputables = horario[0].horasComputables.totalHoras;
        horario.forEach(({ inicio, fin, tipo, horasComputables }) => {
            if (tipo === 'horario') {
                const [inicioHoras, inicioMinutos] = inicio.split('h').map(Number);
                const [finHoras, finMinutos] = fin.split('h').map(Number);
                const inicioEnMinutos = inicioHoras * 60 + inicioMinutos;
                const finEnMinutos = finHoras * 60 + finMinutos;
                let intervaloMinutos = finEnMinutos - inicioEnMinutos;
                if (intervaloMinutos < 0) {
                    intervaloMinutos += 1440;
                }
                minutosReales += intervaloMinutos;
            } else if (tipo === 'sinHorario') {
                minutosReales += (horasComputables.volumenHorario / 7) * 60;
            } else if (tipo === 'sinDatos') {
                minutosReales += 0;
            }
        });
        const horesExtra = minutosReales / 60 - horasComputables > 0 ? Number((minutosReales / 60 - horasComputables).toFixed(2)) : 0;
        const horesReals = minutosReales / 60 > 0 ? Number((minutosReales / 60).toFixed(2)) : 0;
        return { horesReals, horesExtra }
    };

    const handleButtonClick = async () => {
        if (fileInputRef.current) {
            fileInputRef.current.value = null;
        }
        fileInputRef.current.click();
    };

    const handleClickRegistrar = () => {
        const fecha = `${valueDatePicker.getFullYear()}-${valueDatePicker.getMonth() + 1
            }`;
        const idRegistre =
            historicHoresExtra.find((item) => item.fecha === fecha)?.id || null;
        const registreAGuardar = {
            fecha,
            resultat_calculs: JSON.stringify(arrayDataHoresExtra),
            total,
            usuari,
        };
        if (idRegistre) {
            dispatch(
                setOpenDialog({
                    children: (
                        <>
                            <DialogTitle>Registre existent</DialogTitle>
                            <DialogContent>
                                <DialogContentText>
                                    <span>{`Ja hi ha un registre corresponent al mes ${fecha} a la base de dades. Vols substituir-lo?`}</span>
                                </DialogContentText>
                            </DialogContent>
                            <DialogActions>
                                <Button
                                    onClick={() => {
                                        dispatch(setCloseDialog());
                                    }}
                                    color="secondary"
                                    autoFocus
                                >
                                    No
                                </Button>
                                <Button
                                    onClick={() => {
                                        dispatch(setCloseDialog());
                                        dispatch(
                                            actualitzarHoresExtraAccion(
                                                'hores_extra',
                                                registreAGuardar,
                                                idRegistre
                                            )
                                        );
                                    }}
                                    color="secondary"
                                    autoFocus
                                >
                                    Sí
                                </Button>
                            </DialogActions>
                        </>
                    ),
                })
            );
            return;
        }
        dispatch(registrarHoresExtraAccion('hores_extra', registreAGuardar));
        handleCloseMenu();
    };

    const handleClickReset = () => {
        dispatch(resetDataCarregadaHoresExtraAccion());
        setTotal(0);
        setFileName('Pujar arxiu Excel');
        handleCloseMenu();
    };

    const handleClickMenu = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleCloseMenu = () => {
        setAnchorEl(null);
    };

    const handleGenerarXLS = () => {
        const mes = dataCarregadaHoresExtra.estado
            ? dataCarregadaHoresExtra.fecha
            : `${valueDatePicker.getFullYear()}-${valueDatePicker.getMonth() + 1}`;
        const totalAPassar = dataCarregadaHoresExtra.estado
            ? dataCarregadaHoresExtra.total
            : total;      
        dispatch(generaArchivoXLSHoresExtraAccion(mes, totalAPassar));
        handleCloseMenu();
    };

    return (
        <div>
            <Backdrop className={classes.loading} open={openLoading}>
                <CircularProgress color="inherit" />
            </Backdrop>
            {historicHoresExtra?.length >= 0 && (
                <Grid
                    spacing={1}
                    container
                    direction="row"
                    justifycontent="flex-start"
                    alignItems="flex-start"
                    ref={view}
                >
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: {
                                xs: 'column',
                                sm: 'column',
                                md: 'column',
                                lg: 'row',
                                xl: 'row',
                            },
                            justifyContent: {
                                xs: 'flex-start',
                                sm: 'flex-start',
                                md: 'flex-start',
                                lg: 'space-between',
                                xl: 'space-between',
                            },
                            width: '100%',
                            alignItems: {
                                xs: 'flex-start',
                                sm: 'flex-start',
                                md: 'flex-start',
                                lg: 'center',
                                xl: 'center',
                            },
                            pt: 2,
                            px: 2,
                        }}
                    >
                        <Chip
                            sx={{
                                width: {
                                    xs: '100%',
                                    sm: '100%',
                                    md: '100%',
                                    lg: 'auto',
                                    xl: 'auto',
                                },
                                justifyContent: 'flex-start',
                            }}
                            label="Contador hores extra Casa Amàlia"
                            avatar={<Avatar>1</Avatar>}
                        />
                        <Stack
                            direction={{
                                xs: 'column',
                                sm: 'column',
                                md: 'column',
                                lg: 'row',
                                xl: 'row',
                            }}
                            alignItems={{
                                xs: 'flex-start',
                                sm: 'flex-start',
                                md: 'flex-start',
                                lg: 'center',
                                xl: 'center',
                            }}
                            justifyContent={{
                                xs: 'flex-start',
                                sm: 'flex-start',
                                md: 'flex-start',
                                lg: 'center',
                                xl: 'center',
                            }}
                            spacing={2}
                            sx={{
                                marginTop: {
                                    xs: 2,
                                    sm: 2,
                                    md: 2,
                                    lg: 0,
                                    xl: 0,
                                },
                                width: {
                                    xs: '100%',
                                    sm: '100%',
                                    md: '100%',
                                    lg: 'auto',
                                    xl: 'auto',
                                },
                                '& > *': {
                                    width: {
                                        xs: '100%',
                                        sm: '100%',
                                        md: '100%',
                                        lg: 'auto',
                                        xl: 'auto',
                                    },
                                },
                            }}
                        >
                            <LocalizationProvider dateAdapter={DateFnsUtils} locale={ca}>
                                <DatePicker
                                    size="small"
                                    views={['year', 'month']}
                                    inputVariant="outlined"
                                    fullWidth
                                    format="MM/yyyy"
                                    label="Mes a gestionar"
                                    minDate={adjustMonth(currentDate, -4)}
                                    maxDate={adjustMonth(currentDate, 4)}
                                    value={valueDatePicker}
                                    onChange={setValueDatePicker}
                                    renderInput={(params) => <TextField {...params} />}
                                />
                            </LocalizationProvider>
                            <Button
                                sx={{ mb: 0.5, px: 4, py: 1.6, minWidth: 230 }}
                                variant="contained"
                                color="primary"
                                size="large"
                                startIcon={<ContentPasteSearchIcon />}
                                onClick={handleClickMenu}
                            >
                                Contador H. extra
                            </Button>
                            <StyledMenu
                                anchorEl={anchorEl}
                                open={openMenu}
                                onClose={handleCloseMenu}
                            >
                                <Input
                                    type="file"
                                    accept=".xlsx"
                                    onChange={handleFileUpload}
                                    inputRef={fileInputRef}
                                    style={{ display: 'none' }}
                                />
                                <MenuItem onClick={handleButtonClick} disableRipple>
                                    <UploadFileIcon />
                                    Pujar arxiu
                                </MenuItem>
                                <Divider sx={{ my: 0.5 }} />
                                <MenuItem
                                    onClick={handleClickRegistrar}
                                    disableRipple
                                    disabled={
                                        dataCarregadaHoresExtra.estado || !arrayDataHoresExtra
                                    }
                                >
                                    <SaveIcon />
                                    Registrar dades
                                </MenuItem>
                                <MenuItem
                                    onClick={handleGenerarXLS}
                                    disableRipple
                                    disabled={
                                        !dataCarregadaHoresExtra.estado && !arrayDataHoresExtra
                                    }
                                >
                                    <TextSnippetIcon />
                                    Generar resultat Excel
                                </MenuItem>
                                <MenuItem
                                    onClick={handleClickReset}
                                    disableRipple
                                    disabled={
                                        !dataCarregadaHoresExtra.estado && !arrayDataHoresExtra
                                    }
                                >
                                    <CancelIcon />
                                    Reset dades
                                </MenuItem>
                            </StyledMenu>
                        </Stack>
                    </Box>
                    <Grid
                        spacing={2}
                        container
                        direction="row"
                        justifycontent="flex-start"
                        alignItems="flex-start"
                        p={2}
                    >
                        <Grid item xs={12} sm={12} md={12} lg={6} xl={8}>
                            <Box
                                mt={2}
                                color="secondary.contrastText"
                                bgcolor={
                                    dataCarregadaHoresExtra.estado ? '#618BF1' : 'secondary.main'
                                }
                                className={classes.boxStl}
                                sx={{
                                    width: '100%',
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    alignItems: 'center',
                                    height: 50,
                                }}
                            >
                                <Stack
                                    direction={'row'}
                                    spacing={1}
                                    alignItems="center"
                                    sx={{ width: '100%' }}
                                >
                                    <TextSnippetIcon sx={{ marginTop: -0.3 }} />
                                    <Typography variant="body2" noWrap>
                                        {dataCarregadaHoresExtra.estado
                                            ? `Registre: ${dispatch(
                                                convertirStringAMesAnio(dataCarregadaHoresExtra.fecha)
                                            )}`
                                            : `Arxiu Excel a gestionar: ${fileName}`}
                                    </Typography>
                                </Stack>
                            </Box>
                            <Box
                                mt={2}
                                pr={{ xs: 1, sm: 1, md: 1, lg: 1 }}
                                className={classes.scrollable}
                                sx={{
                                    height: {
                                        xs: 'auto',
                                        sm: 'auto',
                                        md: 'auto',
                                        lg: heightScrollable - 160,
                                        xl: heightScrollable - 160,
                                    },
                                }}
                            >
                                <Paper sx={{ width: '100%', overflow: 'hidden' }}>
                                    <TableContainer sx={{ marginBottom: 4 }}>
                                        <Table>
                                            <TableHead>
                                                <TableRow>
                                                    {columns.map((column) => (
                                                        <TableCell
                                                            key={column.id}
                                                            align={column.align}
                                                            style={{ minWidth: column.minWidth }}
                                                        >
                                                            {column.label}
                                                        </TableCell>
                                                    ))}
                                                </TableRow>
                                            </TableHead>
                                            {arrayDataHoresExtra && (
                                                <TableBody>
                                                    {arrayDataHoresExtra.map((row, index) => {
                                                        return (
                                                            <TableRow
                                                                hover
                                                                tabIndex={-1}
                                                                key={`col-${index}`}
                                                            >
                                                                {columns.map((column, index1) => (
                                                                    <TableCell
                                                                        key={`row-${index1}`}
                                                                        align={column.align}
                                                                    >
                                                                        {column.id === 'preu'
                                                                            ? `${row[column.id]}€`
                                                                            : row[column.id]}
                                                                    </TableCell>
                                                                ))}
                                                            </TableRow>
                                                        );
                                                    })}
                                                </TableBody>
                                            )}
                                            <TableFooter>
                                                <TableRow>
                                                    <TableCell></TableCell>
                                                    <TableCell></TableCell>
                                                    <TableCell></TableCell>
                                                    <TableCell></TableCell>
                                                    <TableCell></TableCell>
                                                </TableRow>
                                                <TableRow sx={{ backgroundColor: '#f5f5f5' }}>
                                                    <TableCell>
                                                        <Typography component="span" color="text.primary">
                                                            Total
                                                        </Typography>
                                                    </TableCell>
                                                    <TableCell>
                                                        <Typography component="span" color="text.primary">                                                           
                                                        </Typography>
                                                    </TableCell>
                                                    <TableCell>
                                                        <Typography component="span" color="text.primary">                                                           
                                                        </Typography>
                                                    </TableCell>
                                                    <TableCell sx={{ textAlign: 'right' }}>
                                                        <Typography component="span" color="text.primary">
                                                            {total}
                                                        </Typography>
                                                    </TableCell>
                                                    <TableCell sx={{ textAlign: 'right' }}>
                                                        <Typography component="span" color="text.primary">
                                                            {`${total > 0 ? (total * preuHoraExtra).toFixed(2) : 0}€`}
                                                        </Typography>
                                                    </TableCell>
                                                </TableRow>
                                            </TableFooter>
                                        </Table>
                                    </TableContainer>
                                </Paper>
                            </Box>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={6} xl={4}>
                            <HistoricHoresExtra
                                prHeight={heightScrollable}
                                prWidth={widthScrollable}
                                historicHoresExtra={historicHoresExtra}
                                breakpoints={breakpoints}
                                dataCarregadaHoresExtra={dataCarregadaHoresExtra}
                                setTotal={setTotal}
                            />
                        </Grid>
                    </Grid>
                </Grid>
            )}
            <Snackbar
                open={openSnack}
                autoHideDuration={12000}
                onClose={handleCloseSnack}
            >
                <Alert severity={alert.tipo} onClose={handleCloseSnack}>
                    {alert.mensaje}
                </Alert>
            </Snackbar>
            <CustomDialog />
        </div>
    );
};

export default withRouter(HoresExtra);