import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useIntersection from "../useIntersection";
import { withRouter } from "react-router-dom";
import {
    Box,
    Grid,
    CircularProgress,
    Backdrop,
    Snackbar,
    useMediaQuery,
    Chip,
    Avatar,
    Dialog,
    DialogTitle,
    DialogContent,
    IconButton
} from '@mui/material';
import CancelIcon from '@mui/icons-material/Cancel';

//carga componentes
import HistoricRegala from './HistoricRegala';
import CustomDialog from './CustomDialog';
import RegalaDia from './RegalaDia';

//estilos
import Clases from "../clases";

//importaciones acciones
import {
    obtenerRegalaAccion,
    reseteaExitoRegalaAccion,
    resetDataCarregadaRegalaAccion
} from '../redux/regalaDucks';
import {
    setOnEstemAccion,
    setAlertaAccion,
    Alert,
    Transition
} from '../redux/appDucks';

const getHeightScrollable = () => (window.innerHeight - 100) || (document.documentElement.clientHeight - 100) || (document.body.clientHeight - 100);
const getWidthScrollable = () => (window.innerWidth - 240) || (document.documentElement.clientWidth - 240) || (document.body.clientWidth - 240);

const Regala = (props) => {
    const classes = Clases();
    const dispatch = useDispatch();
    const view = useRef();
    const inViewport = useIntersection(view, "0px");
    // if (inViewport.estado && !inViewport.completado) {
    //     window.location.reload(false);
    // };
    const { activo: logged, usuarioActivo: { nombre: usuari } } = useSelector(store => store.variablesUsuario);
    const {
        loadingAppRegala: openLoading,
        historicRegala,
        errorDeCargaRegala,
        exitoActualizacionRegala,
        exitoEliminarRegala,
        dataCarregadaRegala
    } = useSelector(store => store.variablesRegala);
    const {
        alerta,
    } = useSelector(store => store.variablesApp);

    //refs

    //states
    const [heightScrollable, setHeightScrollable] = useState(getHeightScrollable());
    const [widthScrollable, setWidthScrollable] = useState(getWidthScrollable());
    const [openSnack, setOpenSnack] = useState(false);
    const [alert, setAlert] = useState({});
    const breakpoints = {
        xs: useMediaQuery(theme => theme.breakpoints.only('xs')),
        sm: useMediaQuery(theme => theme.breakpoints.only('sm')),
        md: useMediaQuery(theme => theme.breakpoints.only('md')),
        lg: useMediaQuery(theme => theme.breakpoints.only('lg')),
        xl: useMediaQuery(theme => theme.breakpoints.only('xl'))
    };
    const today = new Date();
    const [openDialogRegala, setOpenDialogRegala] = useState(false);
    const [fetCanvi, setFetCanvi] = useState(false);

    //useEffect

    useEffect(() => {
        if (!logged) {
            props.history.push('/login')
        }
    }, [logged, props.history]);

    useEffect(() => {
        if (inViewport.estado && !inViewport.completado) {
            window.location.reload(false);
        };
    }, [inViewport]);

    useEffect(() => {
        if (breakpoints.xs || breakpoints.sm || breakpoints.md || breakpoints.lg) {
            document.body.classList.add(classes.conScroll);
        } else {
            document.body.classList.add(classes.sinScroll);
        };
    }, []);

    useEffect(() => {
        dispatch(setOnEstemAccion('regala'));
        const resizeListener = () => {
            setHeightScrollable(getHeightScrollable());
            setWidthScrollable(getWidthScrollable());
        };
        window.addEventListener('resize', resizeListener);
        return () => {
            window.removeEventListener('resize', resizeListener);
        }
    }, []);

    useEffect(() => {
        if (!historicRegala) {
            dispatch(obtenerRegalaAccion('regala'));
        };
    }, [historicRegala]);

    useEffect(() => {
        if (dataCarregadaRegala.estado) {
            setOpenDialogRegala(true);
        };
    }, [dataCarregadaRegala]);

    useEffect(() => {
        if (alerta.abierto) {
            setAlert({
                mensaje: alerta.mensaje,
                tipo: alerta.tipo
            })
            setOpenSnack(true);
        }
    }, [alerta]);

    useEffect(() => {
        if (errorDeCargaRegala) {
            dispatch(setAlertaAccion({
                abierto: true,
                mensaje: "Error de connexió amb la base de dades.",
                tipo: 'error'
            }));
        }
    }, [errorDeCargaRegala]);

    useEffect(() => {
        if (exitoActualizacionRegala) {
            dispatch(setAlertaAccion({
                abierto: true,
                mensaje: "Registres actualitzats correctament.",
                tipo: 'success'
            }));
            dispatch(reseteaExitoRegalaAccion());

        }
    }, [exitoActualizacionRegala]);

    useEffect(() => {
        if (exitoEliminarRegala) {
            dispatch(setAlertaAccion({
                abierto: true,
                mensaje: "Registre eliminat correctament.",
                tipo: 'success'
            }));
            dispatch(reseteaExitoRegalaAccion());
        }
    }, [exitoEliminarRegala]);

    //funciones

    const handleCloseSnack = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        };
        setOpenSnack(false);
        dispatch(setAlertaAccion({
            abierto: false,
            mensaje: '',
            tipo: ''
        }));
    };

    const handleCloseDialogRegala = () => {
        setOpenDialogRegala(false);
        if (fetCanvi) {
            setTimeout(() => {
                dispatch(resetDataCarregadaRegalaAccion());
                dispatch(reseteaExitoRegalaAccion());
                setFetCanvi(false);
            }, 1000);
        };
    };

    if (!historicRegala) {
        return;
    };

    return (
        <div>
            {/* {console.log(heightScrollable)} */}
            <Backdrop className={classes.loading} open={openLoading}>
                <CircularProgress color="inherit" />
            </Backdrop>
            {historicRegala?.length > 0 ? (
                <Grid
                    spacing={1}
                    container
                    direction="row"
                    justifycontent="flex-start"
                    alignItems="flex-start"
                    ref={view}
                >
                    <Box
                        sx={{
                            display: "flex",
                            flexDirection: {
                                xs: 'column',
                                sm: 'column',
                                md: 'column',
                                lg: 'row',
                                xl: 'row',
                            },
                            justifyContent: {
                                xs: 'flex-start',
                                sm: 'flex-start',
                                md: 'flex-start',
                                lg: 'space-between',
                                xl: 'space-between',
                            },
                            width: "100%",
                            alignItems: {
                                xs: 'flex-start',
                                sm: 'flex-start',
                                md: 'flex-start',
                                lg: 'center',
                                xl: 'center',
                            },
                            pt: 2,
                            px: 2
                        }}>
                        <Chip sx={{
                            width: {
                                xs: '100%',
                                sm: '100%',
                                md: '100%',
                                lg: 'auto',
                                xl: 'auto'
                            },
                            justifyContent: "flex-start"
                        }} label="Gestió de Targetes Regala Casa Amàlia" avatar={<Avatar>2</Avatar>} />
                    </Box>
                    <Grid
                        spacing={2}
                        container
                        direction="row"
                        justifycontent="flex-start"
                        alignItems="flex-start"
                        p={2}
                    >
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                            <HistoricRegala
                                heightScrollable={heightScrollable}
                                historicRegala={historicRegala}
                                breakpoints={breakpoints}
                                today={today}
                            />
                        </Grid>
                    </Grid>
                </Grid>
            ) : null}
            <Snackbar open={openSnack} autoHideDuration={12000} onClose={handleCloseSnack}>
                <Alert severity={alert.tipo} onClose={handleCloseSnack}>
                    {alert.mensaje}
                </Alert>
            </Snackbar>
            <CustomDialog />
            <Dialog
                open={openDialogRegala}
                TransitionComponent={Transition}
                onClose={handleCloseDialogRegala}
                fullWidth
                maxWidth="lg"
            >
                <Box px={3} style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', marginBottom: "-20px" }}>
                    <DialogTitle>
                        Redimir targeta Regala Casa Amàlia
                    </DialogTitle>
                    <IconButton
                        onClick={handleCloseDialogRegala}
                    >
                        <CancelIcon color='error' />
                    </IconButton>
                </Box>
                <DialogContent>
                    {dataCarregadaRegala.estado && (
                        <RegalaDia
                            regala={dataCarregadaRegala}
                            index={0}
                            setFetCanvi={setFetCanvi}
                        />
                    )}
                </DialogContent>
            </Dialog >
        </div>
    )
}

export default withRouter(Regala)