import React, { } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    Box,
    Grid,
    Typography,
    Stack,
    Avatar,
    List,
    ListItem,
    Paper,
    CardMedia,
    Button,
    ButtonGroup
} from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import regalaConcepcio from '../images/regala_concepcio.png';
import regalaPortvell from '../images/regala_portvell.png';

//carga componentes
import {
    actualitzarRegalaAccion,
    setDataCarregadaRegalaAccion,
    calculCaducitat
} from '../redux/regalaDucks';
import {
    convertirFechaAFormatoDDMMAAAA
} from '../redux/appDucks';

//estilos
import Clases from "../clases";

const RegalaDia = (props) => {
    const {
        regala,
        index,
        setFetCanvi
    } = props;
    const classes = Clases();
    const dispatch = useDispatch();
    const { activo: logged, usuarioActivo: { nombre: usuari } } = useSelector(store => store.variablesUsuario);
    const { dataCarregadaRegala } = useSelector(store => store.variablesRegala);

    //states   

    //useEffect   

    //funciones   

    const handleRedimir = (redimida) => {
        setFetCanvi(true);
        const registreAGuardar = {
            ...regala,
            fecha: dispatch(convertirFechaAFormatoDDMMAAAA(regala.fecha)),
            redimida,
            usuari
        };
        const enviarMail = (redimida === "si");
        dispatch(actualitzarRegalaAccion('regala', registreAGuardar, regala.id, enviarMail, regala.email));
        if (dataCarregadaRegala.estado) {
            dispatch(setDataCarregadaRegalaAccion({
                ...dataCarregadaRegala,
                redimida
            }));
        };
    };

    return (
        <div>
            {/* {console.log(regala)} */}
            <Grid
                spacing={1}
                container
                direction="row"
                justifycontent="flex-start"
                alignItems="flex-start"
            >
                <List sx={{ width: "100%" }}>
                    <ListItem alignItems="center" >
                        <Grid container mt={2}>
                            <Box
                                color="primary.contrastText"
                                className={regala.redimida === 'si' ? classes.boxStl3 : classes.boxStl2}
                                sx={{
                                    width: '100%',
                                    marginTop: -2,
                                    display: "flex",
                                    alignItems: "center",
                                    padding: 1.5
                                }}
                            >
                                <Grid container justifyContent="space-between" alignItems="center" >
                                    <Grid item sx={{ paddingLeft: 1 }}>
                                        <Stack direction={'row'} spacing={1} >
                                            <Avatar sx={{ bgcolor: 'secondary.main', width: 22, height: 22 }}>
                                                <Typography color='secondary.contrastText' variant="body2">{index + 1}</Typography>
                                            </Avatar>
                                            {regala.redimida === 'si' ? (
                                                <Stack direction={'row'} >
                                                    <Typography variant="body2" className={classes.textVerd} sx={{ marginRight: 1 }}>Targeta regala redimida </Typography>
                                                    <CheckIcon fontSize="small" className={classes.textVerd} />
                                                    <Typography variant="body2" className={classes.textVerd} >{'Gestionat per: ' + regala.usuari}</Typography>
                                                </Stack>
                                            ) : (
                                                <Typography variant="body2" >Targeta regala</Typography>
                                            )}
                                        </Stack>
                                    </Grid>
                                    {regala.redimida === 'no' ? (
                                        <Grid item
                                            sx={{
                                                paddingTop: {
                                                    xs: 1,
                                                    sm: 0
                                                }
                                            }}>
                                            <ButtonGroup
                                                variant="outlined"
                                                color="secondary"
                                                size='small'
                                                disableElevation
                                            >
                                                <Button
                                                    onClick={() => handleRedimir("si")}
                                                >
                                                    Redimir
                                                </Button>
                                            </ButtonGroup>
                                        </Grid>
                                    ) : (
                                        <Grid item
                                            sx={{
                                                paddingTop: {
                                                    xs: 1,
                                                    sm: 0
                                                }
                                            }}>
                                            <ButtonGroup
                                                variant="outlined"
                                                color="secondary"
                                                size='small'
                                                disableElevation
                                            >
                                                <Button
                                                    onClick={() => handleRedimir("no")}
                                                >
                                                    Desfer
                                                </Button>
                                            </ButtonGroup>
                                        </Grid>
                                    )}
                                </Grid>
                            </Box>
                            <Grid
                                container
                                mt={2}
                                //justifycontent="space-between"
                                alignItems="flex-start"
                            //flexDirection={"column"}
                            >
                                <Grid item xs={12} sm={12} md={6} lg={6} xl={6} p={2}>
                                    <Stack direction="row" spacing={1} alignItems="baseline" flexWrap="wrap">
                                        <Typography variant="body1" fontWeight="bold" component="span">
                                            Nom:
                                        </Typography>
                                        <Typography variant="body2">
                                            {regala.nom}
                                        </Typography>
                                    </Stack>
                                    <Stack direction="row" spacing={1} alignItems="baseline" flexWrap="wrap">
                                        <Typography variant="body1" fontWeight="bold" component="span">
                                            Email:
                                        </Typography>
                                        <Typography variant="body2">
                                            {regala.email}
                                        </Typography>
                                    </Stack>
                                    <Stack direction="row" spacing={1} alignItems="baseline" flexWrap="wrap">
                                        <Typography variant="body1" fontWeight="bold" component="span">
                                            Data:
                                        </Typography>
                                        <Typography variant="body2">
                                            {dispatch(convertirFechaAFormatoDDMMAAAA(regala.fecha))}
                                        </Typography>
                                    </Stack>
                                    {/* <Stack direction="row" spacing={1} alignItems="baseline" flexWrap="wrap">
                                        <Typography variant="body1" fontWeight="bold" component="span">
                                            Origen:
                                        </Typography>
                                        <Typography variant="body2">
                                            {regala.origen === "portvell" ? "Port Vell" : "Mercat Concepció"}
                                        </Typography>
                                    </Stack> */}
                                    <Stack direction="row" spacing={1} alignItems="baseline" flexWrap="wrap">
                                        <Typography variant="body1" fontWeight="bold" component="span">
                                            Import:
                                        </Typography>
                                        <Typography variant="body2">
                                            {regala.import}
                                        </Typography>
                                    </Stack>
                                    <Stack direction="row" spacing={1} alignItems="baseline" flexWrap="wrap">
                                        <Typography variant="body1" fontWeight="bold" component="span">
                                            Codi:
                                        </Typography>
                                        <Typography variant="body2">
                                            {regala.codi}
                                        </Typography>
                                    </Stack>
                                    {(regala.redimida === 'no' && dispatch(calculCaducitat(regala.fecha))) && (
                                        <Stack direction="row" spacing={1} alignItems="baseline" flexWrap="wrap">
                                            <Typography variant="body1" fontWeight="bold" component="span" color="error">
                                                Targeta caducada
                                            </Typography>
                                        </Stack>
                                    )}
                                </Grid>
                                <Grid item xs={12} sm={12} md={6} lg={6} xl={6} sx={{
                                    display: 'flex',
                                    justifyContent: { xs: "flex-start", sm: "flex-start", md: "flex-end", lg: "flex-end", xl: "flex-end" },
                                }}>
                                    <CardMedia
                                        component="img"
                                        image={regalaConcepcio}
                                        alt=""
                                        sx={{
                                            height: { xs: 120, sm: 150, md: 180, lg: 130, xl: 180 },
                                            width: { xs: "auto", sm: "auto", md: "auto", lg: "100%", xl: "auto" },
                                            objectFit: "contain",
                                            flexShrink: 0,
                                            borderRadius: 5,
                                            boxSizing: 'border-box',
                                            backgroundColor: "#eceff1",
                                        }}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    </ListItem>
                </List>
            </Grid >
        </div >
    )
}

export default RegalaDia