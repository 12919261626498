import { Dialog } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';

//importaciones acciones
import {
    setCloseDialog,
    Transition
} from '../redux/appDucks';

function CustomDialog(props) {
    const dispatch = useDispatch();
    const { dialog, dialog: { options } } = useSelector(store => store.variablesApp);
    return (
        <Dialog
            open={dialog.state}
            TransitionComponent={Transition}
            onClose={(ev) => dispatch(setCloseDialog())}
            {...options}
        />
    );
}

export default CustomDialog;
